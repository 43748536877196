import { fireBase } from '../../firebase/firebase';


export async function validateUser(password) {
    try {
        const user = fireBase.auth().currentUser;
        await fireBase.auth().signInWithEmailAndPassword(user.email, password);
        return true;
    } catch (error) {
        return false;
    }
}

export async function updateProfile(displayName, photoURL) {
    const user = fireBase.auth().currentUser;

    try {
        await user.updateProfile({
            displayName,
            photoURL,
        });

        return true;
    } catch (error) {
        return false;
    }
}

export async function updatePassword(newPassword) {
    const user = fireBase.auth().currentUser;

    try {
        await user.updatePassword(newPassword);
        return true;
    } catch (error) {
        debugger;
        return false;
    }
}

export async function uploadAvatar(imagePath) {
    const fileName = makeid();
    imagePath = imagePath.replace('data:image/jpeg;base64,', '');
    imagePath = imagePath.replace('data:image/png;base64,', '');

    try {
        debugger;

        const res = await fireBase.storage().ref('/profiles/avatars/')
            .child(fileName)
            .putString(imagePath, 'base64', { contentType: 'image/jpg' });

        debugger;
        const url = await res.task.snapshot.ref.getDownloadURL();
        return {
            'success': true,
            url
        }

    } catch (error) {
        debugger;
        console.clear();
        console.log(error);
        return {
            'success': false,
            url: '',
        }
    }
}

function makeid() {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < 10; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}
