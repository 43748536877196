import DataManager from './dtm';
import { fbDatabase, fieldPath } from '../firebase/firebase';


async function queryBy10(ownerId, ids, dataIni, dataFim) {
    const query = fbDatabase
        .collection('movimentos')
        .where('ownerId', '==', ownerId)
        .where('data', '>=', dataIni)
        .where('data', '<=', dataFim)
        .where('convenioId', 'in', ids);

    const snapshot = await new DataManager().getQueryData(query, false);

    if (snapshot.empty) {
        return [];
    }

    const data = snapshot.docs.map((item) => ({
        key: item.id,
        ...item.data(),
    }));

    return data;
}

class RecoConvenioDB extends DataManager {

    state = {
        collection: 'reco-convenio',
        orderBy: ['totalVezesPontuou'], // usado na classe pai
    }
}

const recoConvenioDB = new RecoConvenioDB();
export default recoConvenioDB;