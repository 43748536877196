import { Component } from 'react';
import { UPDATE_USER_URL, UPDATE_MAIL_USER_URL, fireBase, DELETE_USER_URL } from '../../firebase/firebase';
import { message } from 'antd';

class UserAdmin extends Component {

    async createEmail(email, password) {
        try {
            const obj = await fireBase.auth().createUserWithEmailAndPassword(email, password);
            if (!obj) {
                message.error(`Erro ao criar o usuário com o email ${email}`);
                this.setState({ loadingButton: false });
                return undefined;
            }
            return obj;
        } catch (error) {
            debugger;
            console.log(error.code);
            return undefined;
        }
    }

    async activeOrDeactiveUser(email, active) {
        return new Promise((resolve, reject) => {
            const url = UPDATE_USER_URL;
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'token': 'WO-3290JFXCIMAN',
                    'usermail': email,
                    'ativo': active
                },
            }).then((response) => response.json())
                .then((responseData) => {
                    if (responseData.code === 200) {
                        resolve(responseData);
                    }
                    else {
                        reject(responseData);
                    }
                });
        });
    }

    async changeUserMail(oldEmail, newEmail) {
        return new Promise((resolve, reject) => {
            const url = UPDATE_MAIL_USER_URL;
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'token': 'WO-3290JFXCIMAN',
                    'oldemail': oldEmail,
                    'newemail': newEmail,
                },
            }).then((response) => response.json())
                .then((responseData) => {
                    if (responseData.code === 200) {
                        resolve(responseData);
                    }
                    else {
                        reject(responseData);
                    }
                });
        });
    }

    async deleteUser(email, key) {
        return new Promise((resolve, reject) => {
            const url = DELETE_USER_URL;
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'token': 'WO-3290JFXCIMAN',
                    'email': email,
                    'key': key,
                },
            }).then((response) => response.json())
                .then((responseData) => {
                    if (responseData.code === 200) {
                        resolve(responseData);
                    }
                    else {
                        reject(responseData);
                    }
                });
        });
    }
}

const userAdmin = new UserAdmin();
export default userAdmin