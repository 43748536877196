import {
  Component
} from 'react';
import {
  API_ROUTER,
  API_ROUTER_CHARGE
} from '../../firebase/firebase';


async function generateToken(key) {

  return new Promise((resolve, reject) => {
    const url = `${API_ROUTER}token/convenio/byid/get/${key}`;
    fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          //'key': key,
        },
      }).then((response) => response.json())
      .then((responseData) => {
        if (responseData.code === 200) {
          resolve(responseData);
        } else {
          reject(responseData);
        }
      })
      .catch(err => {
        debugger;
        console.log(err);
        reject({});
      })
  });
}

export async function estornoDebito(key, autorizacaoParaCancelamento, valorEmCentavos, token, user) {

  return new Promise((resolve, reject) => {
    const url = API_ROUTER + 'debit/estorno';
    fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'key': key,
          'autorizacaoParaCancelamento': autorizacaoParaCancelamento,
          'valorEmCentavos': valorEmCentavos,
          'Authorization': `bearer ${token}`,
          'byAdmin': true,
          'user': user,
        },
      }).then((response) => response.json())
      .then((responseData) => {
        if (responseData.code === 200) {
          resolve(responseData);
        } else {
          reject(responseData);
        }
      })
      .catch(err => {
        console.log(err);
        reject({});
      })
  });
}


export default class GerenciaNet extends Component {

  async getToken(convenioId) {
    const token = await generateToken(convenioId);
    return token;
  }

  cancelCharge(chargeId, token) {
    return new Promise((resolve, reject) => {
      const url = API_ROUTER_CHARGE + 'cancel';
      const body = JSON.stringify({
        chargeId
      });
      fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `bearer ${token}`
          },
          body,
        }).then((response) => response.json())
        .then((responseData) => {
          if (responseData.code === 200) {
            resolve(responseData);
          } else {
            reject(responseData);
          }
        });
    });
  }

  createCharge(
    value,
    chargeName,
    expires_at,
    customer_name,
    customer_cnpj,
    customer_email,
    customer_phone,
    charge_message,
    token) {

    return new Promise((resolve, reject) => {
      const url = API_ROUTER_CHARGE + 'create';
      debugger;
      const body = JSON.stringify({
        value,
        chargeName,
        expires_at,
        customer: {
          juridical_person: {
            corporate_name: customer_name,
            cnpj: customer_cnpj,
          },
          email: customer_email,
          phone_number: customer_phone
        },
        message: charge_message
      });
      fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `bearer ${token}`
          },
          body,
        }).then((response) => response.json())
        .then((responseData) => {
          debugger;
          if (responseData.code === 200) {
            resolve(responseData);
          } else {
            reject(responseData);
          }
        });
    });
  }

  update(chargeId, expire_at, token) {
    return new Promise((resolve, reject) => {
      const url = API_ROUTER_CHARGE + 'update';
      const body = JSON.stringify({
        chargeId,
        expire_at
      });
      fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `bearer ${token}`
          },
          body,
        }).then((response) => response.json())
        .then((responseData) => {
          resolve(responseData);
        })
        .catch(error => {
          debugger;
          reject(error);
        });
    });
  }
}