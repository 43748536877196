import { getOwnerId } from '../../../components/auth/auth-provider';
import { formatNumber } from '../../../components/cartao/card-provider';
import beneficiarioDB from '../../../dataManager/dtmBeneficiario';
import cartaoDB from '../../../dataManager/dtmCartao';
import convenioDB from '../../../dataManager/dtmConvenio';


export async function getConvenios() {
  const ownerId = await getOwnerId();
  const convenios = await convenioDB.getByOwnerIdCustom(ownerId);
  return convenios
}

export async function getBeneficiarios(convenioId) {
  const ownerId = await getOwnerId();
  const beneficiarios = await beneficiarioDB.getAtivosByConvenioId(ownerId, convenioId);
  return beneficiarios;
}

async function buildData(list, ativo) {
  const data = [];
  for (let index = 0; index < list.length; index++) {
    const card = list[index];

    if ((card.status === 'Aguardando ativação' && !ativo) || card.status.toLowerCase().includes('cancelado')) continue;

    const bnf = await beneficiarioDB.getById(card.beneficiarioId);

    if (bnf === undefined) {
      continue;
    }

    const item = {
      key: card.key,
      beneficiarioId: card.beneficiarioId,
      numero: formatNumber(card.numero),
      nome: beneficiarioDB.format(bnf.nome, bnf.sobrenome),
      validade: card.validAt.toDate().toLocaleDateString(),
      convenio: card.convenio.nome,
      convenioId: card.convenio.id,
      status: card.status,
    }
    data.push(item);
  }
  data.sort((a, b) => (a.nome > b.nome) ? 1 : -1);
  return data;
}

export async function getCards(convenioId, beneficiarioId, ativo) {
  const ownerId = await getOwnerId();
  const conveniosIds = [];
  conveniosIds.push(convenioId);

  let cards = [];

  if (beneficiarioId === 'todos') {
    cards = await cartaoDB.getByConveniosIds(ownerId, conveniosIds, true);
  } else {
    cards = await cartaoDB.getByBeneficiarioId(beneficiarioId);
  }

  const data = await buildData(cards, ativo);
  return data;
}

export async function getAVencer() {
  const ownerId = await getOwnerId();
  const cards = await cartaoDB.getAVencerEm30Dias(ownerId);
  const data = await buildData(cards);
  return data;
}