import { getOwnerId } from '../../../components/auth/auth-provider';
import beneficiarioDB from '../../../dataManager/dtmBeneficiario';
import convenioDB from '../../../dataManager/dtmConvenio';
import movimentoDB from '../../../dataManager/dtmMovimentos';
import moment from 'moment';
import estabelecimentoDB from '../../../dataManager/dtmEstabelecimento';

export async function getConvenios() {
  const ownerId = await getOwnerId();
  const convenios = await convenioDB.getByOwnerIdCustom(ownerId);
  return convenios
}

export async function getBeneficiarios(convenioId) {
  const ownerId = await getOwnerId();
  const beneficiarios = await beneficiarioDB.getAtivosByConvenioId(ownerId, convenioId);
  return beneficiarios;
}

export async function buildChart(conveniosIds, dataInicial, dataFinal, beneficiarioId, fromCache) {
  const ownerId = await getOwnerId();

  const inicio = dataInicial.startOf('day').toDate();
  const fim = dataFinal.endOf('day').toDate();

  let movimentos = [];

  if (beneficiarioId === "todos") {
    movimentos = await movimentoDB.getByPeriodo(inicio, fim, ownerId, conveniosIds, fromCache);
  } else {
    movimentos = await movimentoDB.getByBeneficiarioAndPeriodo(beneficiarioId, inicio, fim, fromCache);
  }

  let reducedPorData = movimentos.reduce((result, arr) => {
    const id = secsToDate(arr.data.seconds)
    result[id] = result[id] || [];

    let creditoPre = 0;
    if (arr.creditoPre !== undefined) {
      creditoPre = (arr.creditoPre / 100);
    }

    let debito = arr.valorDebitadoSaldo && arr.valorDebitadoSaldo > 0 ? arr.valorDebitadoSaldo : 0;
    let debitoPre = arr.valorDebitadoSaldoPre && arr.valorDebitadoSaldoPre > 0 ? arr.valorDebitadoSaldoPre : 0;
    let credito = arr.credito;

    // Compras canceladas não aparecem nos movimentos
    if (debito > 0 && arr.cancelada) {
      debito = 0;
    }

    // Compras estornadas não aparecem nos movimentos
    if (credito > 0 && arr.estorno) {
      credito = 0;
    }

    result[id] = {
      debito: result[id].debito ? result[id].debito + (debito / 100) : (debito / 100),
      debitoPre: result[id].debitoPre ? result[id].debitoPre + (debitoPre / 100) : (debitoPre / 100),
      credito: result[id].credito ? result[id].credito + (credito / 100) : (credito / 100),
      creditoPre: result[id].creditoPre !== undefined ? result[id].creditoPre + creditoPre : creditoPre,
      saldo: result[id].saldo ? result[id].saldo + (arr.saldo / 100) : (arr.saldo / 100),
      data: moment(arr.data.seconds * 1000),
    }

    return result;
  }, []);

  reducedPorData = fillEmptyDays(dataInicial, dataFinal, reducedPorData);

  let reducedPorEstabelecimento = movimentos.reduce((result, arr) => {
    if (arr.credito === 0 && (arr.creditoPre === undefined || arr.creditoPre === 0) && arr.autorizacao) {
      const id = arr.autorizacao ? arr.autorizacao.estabelecimento.cnpj : undefined;
      if (id) {
        result[id] = result[id] || [];
      }
      let tipoDebito = 'Débito';
      if (arr.valorDebitadoSaldo > 0 && arr.valorDebitadoSaldoPre > 0) {
        tipoDebito = 'Débito e Crédito';
      }
      else if (arr.valorDebitadoSaldoPre > 0) {
        tipoDebito = 'Crédito';
      }

      result[id] = {
        debito: result[id].debito ? result[id].debito + (arr.valorDebitadoSaldo / 100) : (arr.valorDebitadoSaldo / 100),
        debitoPre: result[id].debitoPre ? result[id].debitoPre + (arr.valorDebitadoSaldoPre / 100) : (arr.valorDebitadoSaldoPre / 100),
        estabelecimento: id,
        tipoDebito,
        estorno: result.estorno !== undefined ? result.estorno : false,
      }
    }

    return result;
  }, []);


  let tableData = [];

  let debitoComum = 0;
  let debitoDescFolha = 0;

  reducedPorEstabelecimento = await Promise.all(Object.values(reducedPorEstabelecimento).map(async (item) => {

    let key = 0;
    const cnpj = item.estabelecimento
    const estabelecimento = await estabelecimentoDB.getByCNPJ(cnpj);

    let element = {
      estabelecimento: {
        cnpj: cnpj,
        nome: estabelecimento ? estabelecimento[0].nomeFantasia : ''
      },
    }

    if (item.debito && item.debito > 0) {
      const data = {
        key,
        ...element,
        debito: beneficiarioDB.formatarMoeda(item.debito),
        tipoDebito: 'Débito',
      }
      key++;
      debitoComum += item.debito;
      // Ao imprimir os dados do estabelecimento no primeiro lote, limpo para que não
      // apareça no segundo
      element = {};
      tableData.push(data);
    }

    if (item.debitoPre && item.debitoPre > 0) {
      const data = {
        key,
        ...element,
        debito: beneficiarioDB.formatarMoeda(item.debitoPre),
        tipoDebito: 'Débito (desc. folha)'
      }
      key++;
      debitoDescFolha += item.debitoPre;
      tableData.push(data);
    }
  }));

  const key = tableData.length;
  const itemDebito = {
    debito: beneficiarioDB.formatarMoeda(debitoComum),
    estabelecimento: {
      nome: 'Total compras a Débito'
    },
    key: key + 1,
  }

  const itemCredito = {
    debito: beneficiarioDB.formatarMoeda(debitoDescFolha),
    estabelecimento: {
      nome: 'Total compras a Crédito (Desc. folha)'
    },
    key: key + 2,
  }

  tableData.push(itemDebito);
  tableData.push(itemCredito);

  return {
    chartData: reducedPorData,
    tableData: tableData
  };
}

function fillEmptyDays(inicio, fim, info) {
  const days = fim.diff(inicio, "days");
  let novoInfo = [];

  for (let i = 0; i <= days; i++) {
    let data = moment(fim).startOf("day").subtract(i, "days");
    let item = Object.values(info).find((element) => element.data.startOf("day").diff(data, "days") === 0);
    if (item) {
      novoInfo.push({
        debito: item.debito,
        debitoPre: item.debitoPre,
        credito: item.credito,
        creditoPre: item.creditoPre,
        saldo: item.saldo,
        data: item.data.toDate()
      })
    } else {
      const momentData = moment(data)
      novoInfo.push({
        debito: 0,
        debitoPre: 0,
        credito: 0,
        creditoPre: 0,
        saldo: 0,
        data: data.toDate()
      })
    }
  }
  novoInfo = novoInfo.sort((a, b) => a.data > b.data ? 1 : -1)
  return novoInfo;
}


function secsToDate(secs) {
  var t = moment(secs * 1000)
  return t.date() + "/" + (t.month() + 1);
}