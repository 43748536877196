import DataManager from './dtm';
import { fbDatabase, fbFirestore } from '../firebase/firebase';
import { formatNumber } from '../../src/components/cartao/card-provider';


class BeneficiarioDB extends DataManager {

    state = {
        collection: 'beneficiarios',
        orderBy: ['nome', 'sobrenome'], // usado na classe pai
    }

    async getBeneficiarios(ownerId) {
        let query = fbDatabase.collection(this.state.collection)

        if (ownerId) {
            query = query.where('ownerId', '==', ownerId);
        }

        const snapshot = await this.getQueryData(query, false);

        const data = snapshot.docs.map((doc) => ({
            key: doc.id,
            ...doc.data(),
        }));

        return data;
    }

    async setKeywords(doc) {
        await fbDatabase
            .collection(this.state.collection)
            .doc(doc.key)
            .update({
                keywords: doc.keywords
            })
    }

    async getByOwnerIdCustom(gestorId, limit, fromCache, update) {
        let query = fbDatabase
            .collection(this.state.collection)
            .where('ownerId', '==', gestorId)
            .orderBy('nome')
            .orderBy('sobrenome');

        if (limit) {
            query = query.limit(limit);
        }

        const updateEndpoint = update ? '/beneficiarioDB/getByOwnerIdCustom' : undefined;

        const snapshot = await this.getQueryData(query, fromCache, updateEndpoint);

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data(),
            cartaoFormatado: item.data().cartao ? formatNumber(item.data().cartao.numero) : '',
            ativoStr: item.data().ativo ? 'Sim' : 'Não',
        }));

        return data;
    }

    async getByConvenioIdAndAtivo(convenioId, ativo) {
        const query = fbDatabase
            .collection(this.state.collection)
            .where('convenio.id', '==', convenioId)
            .where('ativo', '==', ativo)
            .orderBy('nome')
            .orderBy('sobrenome');

        const snapshot = await this.getQueryData(query, false);

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data(),
            ativoStr: item.data().ativo ? 'Sim' : 'Não',
        }));

        return data;
    }

    async getByPalavraChaveLimited(ownerId, words, limit = 5, alreadyFoundProducts = [], fromCache, update) {
        let query = fbDatabase
            .collection(this.state.collection)
            .where('ownerId', '==', ownerId);

        for (let i of words) {
            const keyword = 'keywords.' + i;
            query = query.where(keyword, '==', true);
        }

        if (alreadyFoundProducts.length > 0) {
            query = query.where(fbFirestore.FieldPath.documentId(), 'not-in', alreadyFoundProducts)
        }

        query = query.limit(limit);

        const updateEndpoint = update ? '/beneficiarioDB/getByPalavraChaveLimited' : undefined;

        const snapshot = await this.getQueryData(query, fromCache, updateEndpoint);

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data(),
            cartaoFormatado: item.data().cartao ? formatNumber(item.data().cartao.numero) : '',
            ativoStr: item.data().ativo ? 'Sim' : 'Não',
        }));

        return data;
    }

    async getByPalavraChaveLimitedAndAdminId(ownerId, administradorId, words, limit = 5, alreadyFoundProducts = [], fromCache, update) {
        let query = fbDatabase
            .collection(this.state.collection)
            .where('ownerId', '==', ownerId)
            .where('administrador.id', '==', administradorId);

        for (let i of words) {
            const keyword = 'keywords.' + i;
            query = query.where(keyword, '==', true);
        }

        if (alreadyFoundProducts.length > 0) {
            query = query.where(fbFirestore.FieldPath.documentId(), 'not-in', alreadyFoundProducts)
        }

        query = query.limit(limit);

        const updateEndpoint = update ? '/beneficiarioDB/getByPalavraChaveLimitedAndAdminId' : undefined;

        const snapshot = await this.getQueryData(query, fromCache, updateEndpoint);

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data(),
            cartaoFormatado: item.data().cartao ? formatNumber(item.data().cartao.numero) : '',
            ativoStr: item.data().ativo ? 'Sim' : 'Não',
        }));

        return data;
    }

    async getProductsByLetterLimited(ownerId, key, limit, fromCache, update) {
        const end = key.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));

        const query = fbDatabase
            .collection(this.state.collection)
            .where('ownerId', '==', ownerId)
            .where('nome', '>=', key)
            .where('nome', '<', end)
            .limit(limit);

        const updateEndpoint = update ? '/beneficiarioDB/getProductsByLetterLimited' : undefined;

        const snapshot = await this.getQueryData(query, fromCache, updateEndpoint);

        return snapshot.docs.map((doc) => ({
            key: doc.id,
            ...doc.data(),
            ativoStr: doc.data().ativo ? 'Sim' : 'Não'
        }))
    }

    async getProductsByLetterLimitedAndAdminId(ownerId, administradorId, key, limit, fromCache, update) {
        const end = key.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));

        const query = fbDatabase
            .collection(this.state.collection)
            .where('ownerId', '==', ownerId)
            .where('administrador.id', '==', administradorId)
            .where('nome', '>=', key)
            .where('nome', '<', end)
            .limit(limit);

        const updateEndpoint = update ? '/beneficiarioDB/getProductsByLetterLimitedAndAdminId' : undefined;

        const snapshot = await this.getQueryData(query, fromCache, updateEndpoint);

        return snapshot.docs.map((doc) => ({
            key: doc.id,
            ...doc.data(),
            ativoStr: doc.data().ativo ? 'Sim' : 'Não'
        }))
    }

    async getByCPF(cpf) {
        const data = await this.getByParamValue('cpf', cpf);
        return data;
    }

    async getByEmail(email) {
        const data = await this.getByParamValue('email', email);
        return data;
    }

    async getAtivosByConvenioId(ownerId, convenioId, fromCache, update) {
        const query = fbDatabase
            .collection(this.state.collection)
            .where('ownerId', '==', ownerId)
            .where('convenio.id', '==', convenioId)
            .where('ativo', '==', true)
            .orderBy('nome')
            .orderBy('sobrenome');

        const updateEndpoint = update ? '/beneficiarioDB/getAtivosByConvenioId' : undefined;

        const snapshot = await this.getQueryData(query, fromCache, updateEndpoint);

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data(),
            ativoStr: item.data().ativo ? 'Sim' : 'Não',
        }));

        return data;
    }
    async getAtivosByConvenioIdAndCreditoAtivo(ownerId, convenioId) {
        const query = fbDatabase
            .collection(this.state.collection)
            .where('ownerId', '==', ownerId)
            .where('convenio.id', '==', convenioId)
            .where('credito.ativo', '==', true)
            .where('ativo', '==', true)
            .orderBy('nome')
            .orderBy('sobrenome');

        const snapshot = await this.getQueryData(query, false);

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data(),
            ativoStr: item.data().ativo ? 'Sim' : 'Não',
        }));

        return data;
    }


    async getByOwnerIdAndAdminId(ownerId, administradorId, limit, fromCache, update) {
        let query = fbDatabase
            .collection(this.state.collection)
            .where('ownerId', '==', ownerId)
            .where('administrador.id', '==', administradorId)
            .orderBy('nome')
            .orderBy('sobrenome')

        if (limit) {
            query = query.limit(limit)
        }

        const updateEndpoint = update ? '/beneficiarioDB/getByOwnerIdAndAdminId' : undefined;

        const snapshot = await this.getQueryData(query, fromCache, updateEndpoint);

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data(),
            cartaoFormatado: item.data().cartao ? formatNumber(item.data().cartao.numero) : '',
            ativoStr: item.data().ativo ? 'Sim' : 'Não',
        }));

        return data;
    }

    async getByConvenioIdAndPeriodo(ownerId, dataIni, dataFim, fromCache) {
        const query = fbDatabase
            .collection(this.state.collection)
            .where('ownerId', '==', ownerId)
            .where('cadastro', '>=', dataIni)
            .where('cadastro', '<=', dataFim)
            .orderBy('cadastro');

        const snapshot = await this.getQueryData(query, fromCache);

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data(),
            ativoStr: item.data().ativo ? 'Sim' : 'Não',
        }));

        return data;
    }

    format(nome, sobrenome) {
        try {
            
            let nm = nome.trim().toLowerCase().split(' ');

            nm = (nm.map((word) => word[0].toUpperCase() + word.substring(1))).join(' ');

            let sn = sobrenome.trim().toLowerCase().split(' ');

            sn = (sn.map((word) => word[0].toUpperCase() + word.substring(1))).join(' ');

            return `${nm} ${sn}`;
        } catch (error) {
            debugger;
            console.log('Error in format', error);
            return '';
            
        }
  
    }
}

const beneficiarioDB = new BeneficiarioDB();
export default beneficiarioDB;