import DataManager from './dtm';
import { fbDatabase } from '../firebase/firebase';

function formatDate(date) {
    date = new Date(date);
    return (('0' + (date.getDate())).slice(-2) + '-' + ('0' + (date.getMonth() + 1)).slice(-2)) + '-' + date.getFullYear();
}

function getStatus(status) {
    if (status === 'waiting') {
        return 'Pendente'
    } else if (status === 'paid' || status === 'settled') {
        return 'Pago'
    } else if (status === 'unpaid') {
        return 'Atrasado'
    } else if (status === 'canceled') {
        return 'Cancelado'
    }
    return 'Pendente'
}


class PedidoDB extends DataManager {

    state = {
        collection: 'pedidos',
        orderBy: [], // usado na classe pai
    }

    async getByChargeId(chargeId) {
        const query = fbDatabase
            .collection(this.state.collection)
            .where('boleto.charge_id', '==', chargeId);

        const snapshot = await this.getQueryData(query, false);

        if (snapshot.empty) {
            return undefined;
        }

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            boleto: item.data().boleto,
            convenio: item.data().convenio,
            periodo: `${formatDate(item.data().dataInicial)} até ${formatDate(item.data().dataFinal)}`,
            valorTotal: parseFloat(item.data().valorTotal).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
            totalBeneficiarios: item.data().totalBeneficiarios,
            status: getStatus(item.data().boleto.status),
        }));

        return data;
    }

    async retornarPedidosPorConvenioId(convenioId, filtros) {
        let query = fbDatabase.collection(this.state.collection)
          .where('convenio.id', '==', convenioId);
      
        if (filtros != undefined) {
          let statusFilters = [];
          if (filtros.pagos) {
            statusFilters.push('paid');
          }
          if (filtros.pagamentosEmAberto) {
            statusFilters.push('waiting');
          }
          if (filtros.cancelados) {
            statusFilters.push('canceled');
          }
          if (filtros.pagamentosEmAtraso) {
            statusFilters.push('unpaid');
          }
          if (statusFilters.length > 0) {
            query = query.where('boleto.status', 'in', statusFilters);
          }
        }

        query = await query.orderBy('dataInicial', 'desc').get();

        if (query.docs.length > 0) {
            debugger;
        }
     
        const data = query.docs.map((item) => ({
          key: item.id,
          ...item.data(),
        }));
        return data;
      }
      

    async getByOwnerIdAndPeriodo(ownerId, dataIni, dataFim, status) {
        let query = fbDatabase
            .collection(this.state.collection)
            .where('ownerId', '==', ownerId);

        if (status !== 'todos') {
            if (status === 'draft') {
                query = query.where('rascunho', '==', true);
            } else {
                query = query.where('boleto.status', '==', status);
            }
        }

        query = query
            .where('dataInicial', '>=', dataIni)
            .where('dataInicial', '<=', dataFim)
            .orderBy('dataInicial', 'desc');

        const snapshot = await this.getQueryData(query, false);

        const formatCurrency = (value) => {
            return parseFloat(value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
        };

        const hoje = new Date();
        const rascunhos = status === 'draft' ? snapshot.docs.filter(pedido => !pedido.data().boleto) : null;
        const snapshotDocs = rascunhos ?? snapshot.docs;

        const data = snapshotDocs.map((item) => {
            let {
                boleto,
                convenio,
                dataInicial,
                dataFinal,
                dataCredito,
                totalBeneficiarios,
                valorTotal,
                podeCancelar,
                tipoPedido,
                rascunho,
                valorBeneficio,
                creditado,
                reprocess
            } = item.data();

            dataCredito = dataCredito.toDate();

            const isCreditoFuturo = dataCredito > hoje;
            podeCancelar = podeCancelar !== undefined ? podeCancelar && isCreditoFuturo : isCreditoFuturo;

            return {
                key: item.id,
                boleto,
                convenio,
                totalBeneficiarios,
                dataInicial,
                dataFinal,
                dataCredito, // não mexa!
                podeCancelar,
                valorBeneficio,
                creditado,
                reprocess,
                rascunho: Boolean(rascunho),
                tipoPedido: tipoPedido ?? '',
                periodo: `${formatDate(dataInicial)} até ${formatDate(dataFinal)}`,
                valorTotal: formatCurrency(valorTotal),
                status: boleto ? getStatus(boleto.status) : 'Rascunho',
                dataCreditoStr: formatDate(dataCredito),
            }
        });

        return data;
    }

    async getByOwnerIdAndAdministradorIdAndPeriodo(ownerId, administradorId, dataIni, dataFim, status) {
        let query = fbDatabase
            .collection(this.state.collection)
            .where('ownerId', '==', ownerId)
            .where('administrador.id', '==', administradorId)

        if (status !== 'todos') {
            if (status === 'draft') {
                query = query.where('rascunho', '==', true);
            } else {
                query = query.where('boleto.status', '==', status);
            }
        }
        query = query
            .where('dataInicial', '>=', dataIni)
            .where('dataInicial', '<=', dataFim)
            .orderBy('dataInicial', 'desc');

        const snapshot = await this.getQueryData(query, false);

        const formatCurrency = (value) => {
            return parseFloat(value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
        };

        const hoje = new Date();
        const rascunhos = status === 'draft' ? snapshot.docs.filter(pedido => !pedido.data().boleto) : null;
        const snapshotDocs = rascunhos ?? snapshot.docs;

        const data = snapshotDocs.map((item) => {
            let {
                boleto,
                convenio,
                dataInicial,
                dataFinal,
                dataCredito,
                totalBeneficiarios,
                valorTotal,
                podeCancelar,
                tipoPedido,
                rascunho,
                valorBeneficio,
                creditado,
                reprocess
            } = item.data();

            dataCredito = dataCredito.toDate();

            const isCreditoFuturo = dataCredito > hoje;
            podeCancelar = podeCancelar !== undefined ? podeCancelar && isCreditoFuturo : isCreditoFuturo;

            return {
                key: item.id,
                boleto,
                convenio,
                totalBeneficiarios,
                dataInicial,
                dataFinal,
                dataCredito, // não mexa!
                podeCancelar,
                valorBeneficio,
                creditado,
                reprocess,
                rascunho: Boolean(rascunho),
                tipoPedido: tipoPedido ?? '',
                periodo: `${formatDate(dataInicial)} até ${formatDate(dataFinal)}`,
                valorTotal: formatCurrency(valorTotal),
                status: boleto ? getStatus(boleto.status) : 'Rascunho',
                dataCreditoStr: formatDate(dataCredito),
            }
        });

        return data;
    }

    async getByConvenioIdPeriodo(convenioId, dataIni, dataFim) {
        const query = fbDatabase
            .collection(this.state.collection)
            .where('convenio.id', '==', convenioId)
            .where('dataInicial', '>=', dataIni)
            .where('dataInicial', '<=', dataFim);

        const snapshot = await this.getQueryData(query, false);

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data(),
        }));

        return data;
    }

    async getBeneficiariosDoPedido(pedidoId) {
        const query = fbDatabase
            .collection(this.state.collection)
            .doc(pedidoId)
            .collection('beneficiarios');

        const snapshot = await this.getQueryData(query, false);

        const data = snapshot.docs.map((item) => ({
            regKey: item.id,
            key: item.data().id,
            beneficiario: item.data().nome,
            nome: item.data().nome,
            sobrenome: item.data().sobrenome ? item.data().sobrenome : '',
            valor: item.data().valor,
        }));

        return data;
    }

    async addBeneficiariosPedido(pedidoId, beneficiarios) {
        for (let index = 0; index < beneficiarios.length; index++) {
            const item = beneficiarios[index];
            if (item.valorEmCentavos !== undefined && item.valorEmCentavos > 0) {
                fbDatabase.collection(this.state.collection)
                    .doc(pedidoId).collection('beneficiarios')
                    .add({
                        id: item.key,
                        nome: item.nome,
                        sobrenome: item.sobrenome,
                        valor: item.valor,
                        valorEmCentavos: item.valorEmCentavos,
                    });
            }
        }
    }

    async updateBeneficiariosPedido(pedidoId, beneficiarios, removed) {

        // Primeiro excluo os beneficiários que foram removidos pelo usuário
        for (let index = 0; index < removed.length; index++) {
            const docRef = removed[index];
            await fbDatabase.collection(this.state.collection)
                .doc(pedidoId)
                .collection('beneficiarios')
                .doc(docRef)
                .delete()
                .catch(error => {
                    debugger;
                    console.error('Erro ao excluir o documento', error);
                });
        }

        // Segundo, atualizo ou adiciono os beneficiários movimentados pelo usuário
        for (let index = 0; index < beneficiarios.length; index++) {
            const item = beneficiarios[index];
            if (item.valorEmCentavos !== undefined && item.valorEmCentavos > 0) {
                if (item.modified) {
                    fbDatabase.collection(this.state.collection)
                        .doc(pedidoId)
                        .collection('beneficiarios')
                        .doc(item.regKey)
                        .update({
                            id: item.key,
                            nome: item.nome,
                            valor: item.valor,
                            valorEmCentavos: item.valorEmCentavos,
                        })
                        .catch(error => {
                            debugger;
                            console.error('Erro ao excluir o documento', error);
                        });
                } else if (item.added) {
                    const list = [];
                    list.push(item);
                    await this.addBeneficiariosPedido(pedidoId, list);
                }
            }
        }


    }
}

const pedidoDB = new PedidoDB();
export default pedidoDB;