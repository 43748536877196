
import React from 'react';
import {
  Form,
  Input,
  Tooltip,
  Button,
  Divider,
  InputNumber,
  Icon,
  Select,
  Tabs,
  Row,
  Col,
  Switch,
  message,
} from 'antd';

import { ESTABELECIMENTO_NOVA_CHAVE_API } from '../../funcs/constants';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import './ConvenioCRUD.css';
import { getOwnerId, isCashbackPresent, isDebitPresent, isLoyaltyPresent } from '../../auth/auth-provider';
import { isCNPJ, formatCNPJ, clearCpfFormat, encrypt, decrypt } from '../../funcs/utils';
import mensagem from '../../../components/messages/message';
import convenioDB from '../../../dataManager/dtmConvenio';
import { v4 as uuidv4 } from 'uuid';
import CurrencyInput from 'react-currency-input';
import userAdmin from '../../userAdmin/userAdmin';
import { fbFirestore, fireBase } from '../../../firebase/firebase';
import usuarioDB from '../../../dataManager/dtmUsuario';
import PasswordStrengthBar from 'react-password-strength-bar';

const { TabPane } = Tabs;
const { Option } = Select;

class ConvenioCRUD extends React.Component {
  state = {
    loading: false,
    bloquearBotao: false,
    administradores: [],
    selecteds: [],
    confirmDirty: false,
    autoCompleteResult: [],
    data: {
      key: this.props.record.key,
      razaoSocial: '',
      nomeFantasia: '',
      cnpj: '',
      email: '',
      endereco: {
        logradouro: '',
        bairro: '',
        cidade: '',
        uf: '',
        telefone: '',
      },
      limiteCreditoConvenio: 0,
      prazo: 30,
      cashback: 0,
      fidelidade: 0,
      gerarCobranca: "sim",
      credito: {
        ativo: "não",
        valor: 0,
      },
      login: {
        login: '',
        password: ''
      }
    },
    integracao: {
      ativo: false,
      key: ''
    },
    permissoes: {
      financeiro: false,
      cashback: false,
      fidelidade: false,
    },
  };

  constructor(props) {
    super(props);
    this.cancelClick = this.cancelClick.bind(this);
    this.selectHandleChange = this.selectHandleChange.bind(this);
    this.selectCobrancaChange = this.selectCobrancaChange.bind(this);
    this.selectCreditoChange = this.selectCreditoChange.bind(this);
    this.valueLimiteCreditoConvenioChange = this.valueLimiteCreditoConvenioChange.bind(this);
    this.valueCreditoChange = this.valueCreditoChange.bind(this);
    this.onSelectLogin = this.onSelectLogin.bind(this);
    this.passwordChange = this.passwordChange.bind(this);
    this.scoreCheck = this.scoreCheck.bind(this);
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (err) {
        var keys = [];
        for (var k in err) {
          keys.push(k);
        }
        let counter = 1;
        for (let index = 0; index < keys.length; index++) {
          const key = keys[index];
          if (counter <= 3) {
            message.error(err[key].errors[0].message);
            counter += 1;
          }
        }
        return;
      }

      this.setState({ loading: true });

      const gravarAdministradores = [];

      if (!Array.isArray(this.state.selecteds)) {
        this.state.selecteds = [this.state.selecteds];
      }

      this.state.selecteds.forEach(obj => {
        const administrador = this.state.administradores.filter(item => {
          return item.key === obj;
        })[0];

        gravarAdministradores.push({
          id: administrador.key,
          nome: administrador.props.children
        });
      });


      if (!isCNPJ(values.cnpj)) {
        mensagem.openNotificationWithIcon('error', 'CNPJ inválido', 'O CNPJ informado não está correto.');
        return;
      }
      values.cnpj = formatCNPJ(values.cnpj);


      // procuro pelo CNPJ para checar se está repetido
      let snapshot = await convenioDB.getByCNPJ(values.cnpj);
      // Verifico se está havendo uma duplicação de CNPJ
      if (snapshot !== undefined) {
        if ((this.props.editMode && snapshot[0].key !== this.props.record.key) ||
          (this.props.editMode && snapshot.length > 1) || (!this.props.editMode)) {
          mensagem.openNotificationWithIcon('error', 'Duplicação', 'O CNPJ informado já foi cadastrado.');
          return;
        }
      }

      if (values.email) {
        // procuro pelo email para checar se está repetido
        snapshot = await convenioDB.getByEmail(values.email);
        if (snapshot !== undefined) {
          if ((this.props.editMode && snapshot[0].key !== this.props.record.key) ||
            (this.props.editMode && snapshot.length > 1) || (!this.props.editMode)) {
            mensagem.openNotificationWithIcon('error', 'Duplicação', 'O email informado já foi cadastrado.');
            this.setState({ loading: false });
            return;
          }
        }
      }

      let valorEmCentavosLimiteCreditoPadraoConvenio = 0;
      if (values.valorCredito) {
        valorEmCentavosLimiteCreditoPadraoConvenio = Number(values.valorCredito.replace('R$ ', '').split('.').join('').split(',').join('.'));
        valorEmCentavosLimiteCreditoPadraoConvenio = valorEmCentavosLimiteCreditoPadraoConvenio * 100;
      }


      debugger;
      let limiteCreditoConvenio = undefined;
      if (values.limiteCreditoConvenio) {
        limiteCreditoConvenio = Number(values.limiteCreditoConvenio.toString().replace('R$ ', '').split('.').join('').split(',').join('.'));
        limiteCreditoConvenio = limiteCreditoConvenio * 100;
      }

      const ownerId = await getOwnerId();

      const item = {
        ativo: values.ativo.toLocaleLowerCase() === 'sim' ? true : false,
        razaoSocial: values.razaoSocial,
        nomeFantasia: values.nomeFantasia,
        cnpj: values.cnpj,
        administrador: {
          ...gravarAdministradores[0]
        },
        endereco: {
          logradouro: values.endereco,
          bairro: values.bairro,
          cidade: values.cidade,
          uf: values.uf,
          telefone: values.telefone
        },
        gerarCobranca: values.gerarCobranca === 'sim' ? true : false,
        credito: {
          ativo: values.operaCredito === 'sim' ? true : false,
          valor: values.valorCredito ? values.valorCredito : 0,
          valorEmCentavos: valorEmCentavosLimiteCreditoPadraoConvenio,
        },
        ownerId,
      }

      if (limiteCreditoConvenio) {
        item.limiteCreditoConvenio = limiteCreditoConvenio; 
      }

      if (this.state.data.integracao !== undefined) {
        item.integracao = this.state.data.integracao;
      }

      if (values.email) {
        item.email = values.email;
      }

      if (values.prazo) {
        item.prazo = values.prazo;
      }

      debugger;
      if (!values.loginEmail && values.loginEmail !== undefined) {
        const {
          login,
          password
        } = values;

        item.login = {
          login,
          password: encrypt(password)
        }
      } else if (this.props.record.login) {
        item.login = fbFirestore.FieldValue.delete();
      }

      let novoId = '';
      if (!this.props.editMode) {
        let obj = undefined;
        if (values.email && values.loginEmail) {
          // Antes de mais nada, crio um novo login com o email
          // e os seis dígitos do cnpj como senha
          const cnpj = clearCpfFormat(values.cnpj);

          try {
            obj = await fireBase.auth().createUserWithEmailAndPassword(values.email, cnpj.substr(0, 6));
          } catch (error) {
            if (error.code === 'auth/email-already-in-use') {
              message.error('Este email já está cadastrado');
              this.setState({ loading: false });
              return;
            }
          }

          if (!obj) {
            message.error(`Erro ao criar o administrador com o email ${values.email}`);
            this.setState({ loading: false });
            return;
          }
        }

        const res = await convenioDB.add(item);
        if (res.recorded) {
          novoId = res.id

          if (values.email || !values.loginEmail) {
            const usuario = {
              tipo: 'Convenio',
              ownerId,
              lastPasswordUpdate: new Date()
            }

            usuario.uid = obj ?
              obj.user.uid :
              novoId;

            // Salvo o usuário como gestor
            await usuarioDB.add(usuario);
          }
        }
      } else {
        if (values.email) {
          if (!this.props.record.email) {
            // Antes de mais nada, crio um novo login com o email
            // e os seis dígitos do cnpj como senha

            const cnpj = clearCpfFormat(values.cnpj);

            // eslint-disable-next-line
            let obj = undefined;
            try {
              obj = await fireBase.auth().createUserWithEmailAndPassword(values.email, cnpj.substr(0, 6));
            } catch (error) {
              if (error.code === 'auth/email-already-in-use') {
                message.error('Este email já está cadastrado');
                this.setState({ loading: false });
                return;
              }
            }
          } else {
            const isChanged = this.props.record.email !== values.email;
            const podeSalvar = !isChanged || await mensagem.confirmar('Alteração de email detectada. Confirma?');

            if (!podeSalvar) {
              this.setState({ loading: false });
              message.warning('Verifique o email do administrador');
              return;
            }

            if (isChanged) {
              message.info('Aguarde, estamos alterando o email...');
              await userAdmin.changeUserMail(this.props.record.email, values.email);
            }
          }

          const active = values.ativo.toLocaleLowerCase() === 'sim' ? true : false;
          userAdmin.activeOrDeactiveUser(item.email, active);
        } else if (!values.loginEmail && this.props.record.email) {
          // Excluir o email do registro
          item.email = fbFirestore.FieldValue.delete();

          // Atualizar o uid no registro de usuário e excluir usuário do firebase
          await userAdmin.deleteUser(this.props.record.email, this.props.record.key);
        }

        convenioDB.update(this.props.record.key, item);
      }

      const tipoAtualizacao = this.props.editMode ? 'atualizado' : 'cadastrado';
      mensagem.openNotificationWithIcon('success', 'Perfeito!', `O cadastro ${values.nomeFantasia} foi ${tipoAtualizacao} com sucesso`, 3);

      item['key'] = novoId ? novoId : this.props.record.key;
      item['ativoStr'] = values.ativo.toLocaleLowerCase() === 'sim' ? 'Sim' : 'Não';

      this.props.handleOk(item);
    });
  };

  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  async componentDidMount() {
    const { Option } = Select;
    const administradores = [];

    this.props.record.administradores.forEach(item => {
      administradores.push(<Option key={item.key}>{item.nomeFantasia}</Option>);
    });

    const permissoes = this.state.permissoes;

    permissoes.financeiro = await isDebitPresent();
    permissoes.cashback = await isCashbackPresent();
    permissoes.fidelidade = await isLoyaltyPresent();

    this.setState({ administradores, permissoes });

    const selecteds = [];
    if (this.props.editMode && this.props.record) {
      selecteds.push(this.props.record.administrador.id);
      const data = {
        key: this.props.record.key,
        ativo: this.props.record.ativo ? 'sim' : 'nao',
        razaoSocial: this.props.record.razaoSocial,
        nomeFantasia: this.props.record.nomeFantasia,
        cnpj: this.props.record.cnpj,
        endereco: this.props.record.endereco,
        gerarCobranca: this.props.record.gerarCobranca ? 'sim' : 'nao',
        limiteCreditoConvenio: this.props.record.limiteCreditoConvenio  ? this.props.record.limiteCreditoConvenio / 100 : 0, 
        credito: {
          ativo: this.props.record.credito && this.props.record.credito.ativo ? 'sim' : 'nao',
          valor: this.props.record.credito && this.props.record.credito.valor ? this.props.record.credito.valor : 0,
        },
        prazo: this.props.record.prazo,
        cashback: this.props.record.cashback,
        pontosFidelidade: this.props.record.pontosFidelidade,
        integracao: this.props.record.integracao,
        loginEmail: !Boolean(this.props.record.login)
      };

      if (this.props.record.email) {
        data.email = this.props.record.email;
      }

      if (this.props.record.login) {
        const {
          login,
          password
        } = this.props.record.login;

        data.login = {
          login,
          password: decrypt(password)
        };
      } else {
        data.login = this.state.data.login;
      }

      this.setState({
        selecteds,
        data,
      });
    } else {
      // Só seleciono automaticamente se o length for igual a 1 
      // para evitar erros de cadastramento
      if (this.props.record.administradores.length === 1) {
        selecteds.push(this.props.record.administradores[0].key);
        this.setState({ selecteds });
      }
    }
  }

  uuidSubstring() {
    let uuid = uuidv4();
    let key = uuid.substr(24);

    return key;
  }

  newAPIKey() {
    let isOk = true;
    if (this.state.integracao.key !== '') {
      isOk = mensagem.confirmar(ESTABELECIMENTO_NOVA_CHAVE_API);

    }

    if (!isOk) {
      return;
    }

    let key = this.uuidSubstring();

    this.setState({
      apiKeyCopiado: false,
      data: {
        ...this.state.data,
        integracao: {
          key: key,
          ativo: this.state.data.integracao ? this.state.data.integracao.ativo : false
        }
      }
    });
  }

  ativacaoAPIKey(ativo) {
    let key;

    if (!this.state.data.integracao) {
      key = this.uuidSubstring();
    } else {
      key = this.state.data.integracao.key
    }

    this.setState({
      data: {
        ...this.state.data,
        integracao: {
          key: key,
          ativo: ativo
        }
      }
    });
  }


  cancelClick() {
    this.props.handleCancel();
  }

  selectHandleChange(value) {
    this.setState({ selecteds: value });
  }

  selectCobrancaChange(value) {
    const data = this.state.data;
    data.gerarCobranca = value;
    this.setState({ data });
  }

  selectCreditoChange(value) {
    const data = this.state.data;
    data.credito.ativo = value;
    this.setState({ data });
  }

  valueLimiteCreditoConvenioChange(value) {
    const data = this.state.data;
    data.limiteCreditoConvenio = value;
    this.setState({ data });
  }

  valueCreditoChange(value) {
    const data = this.state.data;
    data.credito.valor = value;
    this.setState({ data });
  }

  onSelectLogin(value) {
    const { data } = this.state;
    data.loginEmail = value;
    this.setState({ data });
  }

  passwordChange(e) {
    const { data } = this.state;
    const { value } = e.target;

    data.login.password = value;
    this.setState({ data });
  }

  scoreCheck(score) {
    let bloquearBotao = true;

    if (score >= 2) {
      bloquearBotao = false;
    }

    this.setState({ bloquearBotao });
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };

    const requerCobranca = this.state.data.gerarCobranca === 'sim' ? true : false;
    const requerCredito = this.state.data.credito && this.state.data.credito.ativo === 'sim' ? true : false;

    return (
      <div className="formConvenio">

        <Form {...formItemLayout} onSubmit={this.handleSubmit}>

          <Tabs defaultActiveKey="geral">
            <TabPane tab="Geral" key="geral">
              <Form.Item
                ref="selectAdministrador"
                label={
                  <span>
                    Administrador&nbsp;
                    <Tooltip title="Escolha o administrador do usuário">
                      <Icon type="question-circle-o" />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator("selectEmpresa", {
                  initialValue: this.state.selecteds,
                  rules: [
                    { required: true, message: "selecione um administrador" }
                  ]
                })(
                  <Select
                    mode="single"
                    style={{ width: "100%" }}
                    placeholder="Selecione um administrador"
                    onChange={this.selectHandleChange}

                    showSearch

                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }

                  >
                    {this.state.administradores}
                  </Select>
                )}
              </Form.Item>

              <Form.Item
                style={{ display: this.state.showAtivo }}
                label={
                  <span>
                    Ativo&nbsp;
                    <Tooltip title={`Esse cadastro está ativo?`}>
                      <Icon type="question-circle-o" />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator('ativo', {
                  initialValue: this.state.data.ativo,
                  rules: [{ required: true, message: `Informe se o cadastro está ativo` }],
                })(

                  <Select style={{ width: 120 }}>
                    <Option value="sim">Sim</Option>
                    <Option value="nao">Não</Option>
                  </Select>

                )}
              </Form.Item>

              <Form.Item
                label={
                  <span>
                    CNPJ&nbsp;
                    <Tooltip title="Qual é o CNPJ?">
                      <Icon type="question-circle-o" />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator('cnpj', {
                  initialValue: this.state.data.cnpj,
                  rules: [{ required: true, message: 'Informe o CNJP', whitespace: false }],
                })(<Input />)}
              </Form.Item>

              <Form.Item
                ref='txtRazaoSocial'
                label={
                  <span>
                    Razão Social&nbsp;
                    <Tooltip title="Qual é a Razão Social?">
                      <Icon type="question-circle-o" />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator('razaoSocial', {
                  initialValue: this.state.data.razaoSocial,
                  rules: [{ required: true, message: 'Informe a Razão Social', whitespace: true }],
                })(<Input />)}
              </Form.Item>

              <Form.Item
                label={
                  <span>
                    Nome Fantasia&nbsp;
                    <Tooltip title="Qual é o Nome Fantasia?">
                      <Icon type="question-circle-o" />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator('nomeFantasia', {
                  initialValue: this.state.data.nomeFantasia,
                  rules: [{ required: true, message: 'Informe o Nome Fantasia', whitespace: true }],
                })(<Input />)}
              </Form.Item>

              {this.state.data.loginEmail && (
                <Form.Item label="E-mail" ref="txtEmail">
                  {getFieldDecorator('email', {
                    initialValue: this.state.data.email,
                    rules: [
                      {
                        type: 'email',
                        message: 'Email não é válido',
                      },
                      {
                        required: false, // https://4cr.atlassian.net/browse/MOUB-18
                        message: 'Informe o email',
                      },
                    ],
                  })(<Input />)}
                </Form.Item>
              )}
            </TabPane>

            {this.state.permissoes.fidelidade && (
              <TabPane tab='Login' key='login'>
                <Form.Item
                  label={
                    <span>
                      Login por Email?&nbsp;
                      <Tooltip title={`O login desse cadastro deve ser feito por email?`}>
                        <Icon type='question-circle-o' />
                      </Tooltip>
                    </span>
                  }
                >
                  {getFieldDecorator('loginEmail', {
                    initialValue: this.state.data.loginEmail,
                    rules: [{ required: true, message: `Informe se o login do cadastro é feito através do email` }],
                  })(
                    <Select style={{ width: 120 }} onChange={this.onSelectLogin}>
                      <Option value={true}>Sim</Option>
                      <Option value={false}>Não</Option>
                    </Select>
                  )}
                </Form.Item>

                <Form.Item
                  label={
                    <span>
                      Login&nbsp;
                      <Tooltip title={`Qual é o login do cadastro?`}>
                        <Icon type='question-circle-o' />
                      </Tooltip>
                    </span>
                  }
                >
                  {getFieldDecorator('login', {
                    initialValue: this.state.data.login.login,
                    rules: [{
                      required: !this.state.data.loginEmail && this.state.data.loginEmail !== undefined,
                      message: `Informe o login do cadastro`
                    }],
                  })(
                    <Input
                      disabled={this.state.data.loginEmail || this.state.data.loginEmail === undefined}
                      style={{ width: '60%' }}
                    />
                  )}
                </Form.Item>

                <Form.Item
                  label={
                    <span>
                      Senha&nbsp;
                      <Tooltip title={`Qual é a senha do login?`}>
                        <Icon type='question-circle-o' />
                      </Tooltip>
                    </span>
                  }
                >
                  {getFieldDecorator('password', {
                    initialValue: this.state.data.login.password,
                    rules: [{
                      required: !this.state.data.loginEmail && this.state.data.loginEmail !== undefined,
                      message: `Informe a senha do login do cadastro`
                    }],
                  })(
                    <Input
                      type='password'
                      style={{ width: '60%' }}
                      onChange={this.passwordChange}
                      disabled={this.state.data.loginEmail || this.state.data.loginEmail === undefined}
                    />
                  )}

                  <PasswordStrengthBar
                    minLength={6}
                    scoreWords={['Senha muito fraca', 'Senha fraca', 'Senha razoável', 'Senha boa', 'Senha forte']}
                    shortScoreWord={this.state.data.login.password ? 'Senha muito curta' : ''}
                    style={{ width: '60%' }}
                    password={this.state.data.login.password}
                    onChangeScore={this.scoreCheck}
                  />
                </Form.Item>
              </TabPane>
            )}

            {(this.state.permissoes.financeiro) ?
              <TabPane tab="Financeiro" key="financeiro">

                <Form.Item label="Limite de Crédito">
                  {getFieldDecorator('limiteCreditoConvenio', {
                    initialValue: this.state.data.limiteCreditoConvenio,
                    rules: [{ required: true, message: 'Informe o limite do crédito do conveniado' }],
                  })(

                    <CurrencyInput
                      className="wrapInputValorCredito"
                      prefix="R$ "
                      decimalSeparator=","
                      thousandSeparator="."
                      onChange={this.valueLimiteCreditoConvenioChange} />
                  )}
                </Form.Item>






                <Form.Item
                  label={
                    <span>
                      Gerar Cobrança?&nbsp;
                      <Tooltip title={`Esse cadastro gera cobrança?`}>
                        <Icon type="question-circle-o" />
                      </Tooltip>
                    </span>
                  }
                >
                  {getFieldDecorator('gerarCobranca', {
                    initialValue: this.state.data.gerarCobranca,
                    onChange: this.selectCobrancaChange,
                    rules: [{ required: true, message: `Informe se o cadastro gera cobrança` }],
                  })(
                    <Select style={{ width: 120 }}>
                      <Option value="sim">Sim</Option>
                      <Option value="nao">Não</Option>
                    </Select>
                  )}
                </Form.Item>

                <Form.Item label="Prazo Pagamento" style={{ display: this.state.data.gerarCobranca === 'sim' ? 'block' : 'none' }}>
                  {getFieldDecorator('prazo', {
                    initialValue: this.state.data.prazo,
                    rules: [{ required: requerCobranca, message: 'Informe os dias de prazo para pagamento' }],
                  })(<InputNumber style={{ width: '30%' }} />)}
                </Form.Item>


                <p style={{ display: this.state.data.gerarCobranca === 'sim' ? 'none' : 'block' }}
                  className="paragrafoAviso"><strong>AVISO, CUIDADO!</strong><label className="labelAviso">Convênios configurados com a opção de não gerar
                    cobrança permitirão a inclusão de saldos de forma irrestrita em seus
                    beneficiários, conforme a <strong>vontade</strong> do operador e nenhuma cobrança
                    será emitida, ficando os custos a cargo do administrador do convênio.</label></p>

                <Form.Item
                  label={
                    <span>
                      Opera crédito?&nbsp;
                      <Tooltip title={`Esse convênio concede crédito aos seus funcionários?`}>
                        <Icon type="question-circle-o" />
                      </Tooltip>
                    </span>
                  }
                >
                  {getFieldDecorator('operaCredito', {
                    initialValue: this.state.data.credito.ativo,
                    onChange: this.selectCreditoChange,
                    rules: [{ required: true, message: `Informe se o convênio trabalha com crédito` }],
                  })(
                    <Select style={{ width: 120 }}>
                      <Option value="sim">Sim</Option>
                      <Option value="nao">Não</Option>
                    </Select>
                  )}
                </Form.Item>

                <Form.Item label="Padrão crédito beneficiários" style={{ display: this.state.data.credito.ativo === 'sim' ? 'block' : 'none' }}>
                  {getFieldDecorator('valorCredito', {
                    initialValue: this.state.data.credito.valor,
                    rules: [{ required: requerCredito, message: 'Informe o valor padrão para crédito dos beneficiários' }],
                  })(

                    <CurrencyInput
                      className="wrapInputValorCredito"
                      prefix="R$ "
                      decimalSeparator=","
                      thousandSeparator="."
                      // value={this.state.data.credito.valor}
                      onChange={this.valueCreditoChange} />
                  )}
                </Form.Item>

              </TabPane>
              : ''
            }

            {this.state.permissoes.fidelidade === true ?

              <TabPane tab="Fidelidade" key="fidelidade">

                <Form.Item label="Chave Integração Moub" extra={this.state.apiKeyCopiado ? "Chave da API copiado." : null}>
                  {getFieldDecorator('integracao', {
                  })(
                    <Row gutter={8}>
                      <Col span={16}>
                        <Input placeholder="Chave Integração"
                          value={this.state.data.integracao ? this.state.data.integracao.key : "- - - - - - -"}
                          addonBefore={
                            this.state.data.integracao ?
                              <Icon type="reload" onClick={() => { this.newAPIKey() }} /> :
                              <Icon type="plus" onClick={() => { this.newAPIKey() }} />
                          }
                          addonAfter={
                            this.state.data.integracao ?
                              <CopyToClipboard text={this.state.data.integracao.key} onCopy={() => this.setState({ apiKeyCopiado: true })}>
                                <Icon type="copy" />
                              </CopyToClipboard> :
                              null
                          } />
                      </Col>
                      <Col span={8}>
                        <Switch
                          checked={this.state.data.integracao ? this.state.data.integracao.ativo : false}
                          checkedChildren={<span>Ativo</span>}
                          unCheckedChildren={<span>Inativo</span>}
                          onChange={(ativo, event) => this.ativacaoAPIKey(ativo)}
                        />
                      </Col>
                    </Row>)}
                </Form.Item>
              </TabPane>
              : ''
            }

            <TabPane tab="Endereço" key="endereco">
              <Form.Item label="Telefone">
                {getFieldDecorator('telefone', {
                  initialValue: this.state.data.endereco.telefone,
                  rules: [{ message: 'Informe o telefone' }],
                })(<Input style={{ width: '100%' }} />)}
              </Form.Item>

              <Form.Item label="Endereço">
                {getFieldDecorator('endereco', {
                  initialValue: this.state.data.endereco.logradouro,
                  rules: [{ required: false, message: 'Informe o endereço' }],
                })(<Input style={{ width: '100%' }} />)}
              </Form.Item>

              <Form.Item label="Bairro">
                {getFieldDecorator('bairro', {
                  initialValue: this.state.data.endereco.bairro,
                  rules: [{ required: false, message: 'Informe o bairro' }],
                })(<Input style={{ width: '50%' }} />)}
              </Form.Item>

              <Form.Item label="Cidade">
                {getFieldDecorator('cidade', {
                  initialValue: this.state.data.endereco.cidade,
                  rules: [{ required: false, message: 'Informe a cidade' }],
                })(<Input style={{ width: '70%' }} />)}
              </Form.Item>

              <Form.Item label="UF">
                {getFieldDecorator('uf', {
                  initialValue: this.state.data.endereco.uf,
                  rules: [{ required: false, message: 'Informe a UF' }],
                })(<Input style={{ width: '20%' }} />)}
              </Form.Item>
            </TabPane>
          </Tabs>

          <Form.Item {...tailFormItemLayout}>
            <Button
              type='primary'
              htmlType='submit'
              loading={this.state.loading}
              disabled={this.state.bloquearBotao && !this.state.data.loginEmail && this.state.data.loginEmail !== undefined}
            >
              Salvar
            </Button>

            <Divider type='vertical' />

            <Button onClick={this.cancelClick}>
              Cancelar
            </Button>

          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default Form.create()(ConvenioCRUD);