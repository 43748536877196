import convenioDB from '../../../dataManager/dtmConvenio';
import { getOwnerId } from '../../../components/auth/auth-provider';
import beneficiarioDB from '../../../dataManager/dtmBeneficiario';
import movimentoDB from '../../../dataManager/dtmMovimentos';


export function getNextColor(color) {
    if (color === "#fff") {
        color = "#eee";
    } else {
        color = "#fff";
    }
    return color;
}

export async function getConvenios() {
    const ownerId = await getOwnerId();
    const convenios = await convenioDB.getByOwnerIdCustom(ownerId, true, true);
    return convenios
}

async function getBeneficiarios(convenioId) {
    const ownerId = await getOwnerId();

    const beneficiarios = await beneficiarioDB.getAtivosByConvenioId(ownerId, convenioId, true, true);

    return beneficiarios;

}

export async function getData(convenioId) {

    const ownerId = await getOwnerId();

    const beneficiarios = await getBeneficiarios(convenioId);

    const creditos = await movimentoDB.getUltimoCredito(ownerId);

    let lista = []

    /* eslint-disable array-callback-return */
    beneficiarios.map((beneficiario) => {
        const ultimoCredito = !creditos[beneficiario.key] ? '--' :
            {
                data: creditos[beneficiario.key].data.toDate().toLocaleDateString(),
                valor: formatCurrency(creditos[beneficiario.key].credito)
            }

        const saldoAtual = (beneficiario.operacao !== undefined && beneficiario.operacao.saldo) ? beneficiario.operacao.saldo : 0;
        const element = {
            beneficiario: {
                id: beneficiario.key,
                nome: beneficiario.nome + ' ' + beneficiario.sobrenome,
                saldoAtual: formatCurrency(saldoAtual),
                ultimoCredito: ultimoCredito,
            }
        }
        lista.push(element);
    })

    lista.sort((a, b) => a.nome > b.nome ? -1 : 1);

    let color = '#fff';
    let key = 0;

    lista.forEach((item) => {
        item["color"] = color;
        item["key"] = key;
        key++;
        color = getNextColor(color);
    })

    return lista


}

function formatCurrency(valor) {
    valor = parseFloat(valor / 100).toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
    });
    return valor;
}

