import movimentoDB from '../../../dataManager/dtmMovimentos';
import estabelecimentoBD from '../../../dataManager/dtmEstabelecimento';
import { getOwnerId } from '../../../components/auth/auth-provider';
import moment from 'moment';
import vertteStorage from '../../../components/localstorage/localstorage';
import { H_24_HORAS } from '../../../components/funcs/constants';
import convenioDB from '../../../dataManager/dtmConvenio';
import beneficiarioDB from '../../../dataManager/dtmBeneficiario';


export async function getConvenios() {
    const ownerId = await getOwnerId();
    const convenios = await convenioDB.getByOwnerIdCustom(ownerId);
    return convenios
}

export async function getBeneficiarios(convenioId) {
    const ownerId = await getOwnerId();
    const beneficiarios = await beneficiarioDB.getAtivosByConvenioId(ownerId, convenioId);
    return beneficiarios;
}

export async function getEstabelecimentos() {
    let estabelecimentos = vertteStorage.getWithExpiry('@reports/estabelecimentos');
    if (!estabelecimentos) {
        const ownerId = await getOwnerId();
        estabelecimentos = await estabelecimentoBD.getByOwnerId(ownerId);
        vertteStorage.setWithExpiry('@reports/estabelecimentos', estabelecimentos, H_24_HORAS);
    }
    return estabelecimentos;
}

export async function getMovimentacaoes(dataIni, dataFim, id, tipoFiltro, beneficiarios) {
    const inicio = dataIni.startOf("day").toDate();
    const fim = dataFim.endOf("day").toDate();

    let movimentos = '';
    if (tipoFiltro === 'beneficiarios') {
        movimentos = await movimentoDB.getByBeneficiarioAndPeriodo(id, inicio, fim);
    } else {
        movimentos = await movimentoDB.getByEstabelecimentoAndPeriodo(id, inicio, fim);
    }

    let totalCreditos = 0;
    let totalDebitos = 0;

    let listaMovimentos = [];

    /* eslint-disable array-callback-return */
    Object.values(movimentos).map((item) => {
        let tipo = item.key.substr(0, 3) === 'est' ? 'Estorno' : (item.credito > 0 || item.creditoPre > 0) ? 'Crédito' : 'Débito';
        if (item.creditoPre > 0) {
            tipo = 'creditoDescFolha';
        }

        let beneficiario = {};

        if (tipo !== 'Crédito' && tipo !== 'creditoDescFolha') {
            if (!item.autorizacao || !item.autorizacao.beneficiario) {
                const ben = beneficiarios.find((b) => b.key === item.beneficiarioId);
                beneficiario = {
                    id: ben ? ben.key : item.beneficiario.id,
                    nome: ben && ben.nome && ben.sobrenome ? ben.nome + ' ' + ben.sobrenome : item.beneficiario.nome,
                }
            } else {
                beneficiario = {
                    id: item.autorizacao.beneficiario.id,
                    nome: item.autorizacao.beneficiario.nome
                }
            }
        } else {
            beneficiario = {
                id: item.beneficiarioId
            }
        }

        const data = formatarData(item.data.seconds * 1000);

        let valor = 0;
        if (item.creditoPre !== undefined && item.creditoPre > 0) {
            valor = formatarMoeda(item.creditoPre);
            tipo = 'Crédito (desc. folha)';
            totalCreditos += item.creditoPre;
        } else if (item.credito > 0) {
            valor = formatarMoeda(item.credito);
            tipo = 'Crédito';
            totalCreditos += item.credito;
        }
        else {
            if (item.valorDebitadoSaldo > 0 && item.valorDebitadoSaldoPre > 0) {
                tipo = 'Débito e Crédito';
                valor = formatarMoeda(item.valorDebitadoSaldo + item.valorDebitadoSaldoPre);
                totalDebitos += item.valorDebitadoSaldo + item.valorDebitadoSaldoPre;
            } else if (item.valorDebitadoSaldoPre > 0) {
                tipo = 'Débito (desc. folha)';
                valor = formatarMoeda(item.valorDebitadoSaldoPre);
                totalDebitos += item.valorDebitadoSaldoPre;
            } else {
                tipo = 'Débito';
                valor = formatarMoeda(item.valorDebitadoSaldo);
                totalDebitos += item.valorDebitadoSaldo;
            }
        }

        // ! Quando houver compra com saldo e com desconto em folha
        if (tipo === 'Débito e Crédito') {
            tipo = 'Débito (desc. folha)'
            valor = formatarMoeda(item.valorDebitadoSaldoPre);
            totalDebitos += item.valorDebitadoSaldoPre;

            const element = {
                key: 'debitoDescFolha' + item.key,
                tipo,
                beneficiario: beneficiario,
                estabelecimento: {
                    id: !tipo.startsWith('Crédito') ? item.autorizacao.estabelecimento.key : '--',
                    nome: !tipo.startsWith('Crédito') ? item.autorizacao.estabelecimento.nomeFantasia : '--'
                },
                valor,
                saldo: formatarMoeda(item.saldo),
                data: data.data,
                hora: data.hora
            }

            listaMovimentos.push(element);

            tipo = 'Débito';
            valor = formatarMoeda(item.valorDebitadoSaldo);
        }

        const element = {
            key: item.key,
            tipo,
            beneficiario: beneficiario,
            convenio: item.convenio,
            estabelecimento: {
                id: !tipo.startsWith('Crédito') ? item.autorizacao && item.autorizacao.estabelecimento.key : '--',
                nome: !tipo.startsWith('Crédito') ? item.autorizacao && item.autorizacao.estabelecimento.nomeFantasia : '--'
            },
            valor,
            saldo: formatarMoeda(item.saldo),
            data: data.data,
            hora: data.hora
        }

        listaMovimentos.push(element);
    })

    listaMovimentos.sort((a, b) => {
        let aa = a.data.split('/').reverse().join(),
            bb = b.data.split('/').reverse().join();
        return aa < bb ? -1 : (aa > bb ? 1 : 0);
    });

    let reduced = listaMovimentos.reduce((result, item) => {
        const id = item.tipo;
        result[id] = result[id] || [];

        result[id].push(item)

        return result;
    }, []);

    const element = {
        todas: listaMovimentos,
        debito: reduced["Débito"] ? reduced["Débito"] : [],
        credito: reduced["Crédito"] ? reduced["Crédito"] : [],
        estorno: reduced["Estorno"] ? reduced["Estorno"] : [],
        creditoDescFolha: reduced["Crédito (desc. folha)"] ? reduced["Crédito (desc. folha)"] : [],
        debitoDescFolha: reduced["Débito (desc. folha)"] ? reduced["Débito (desc. folha)"] : [],
        totalDebitos: formatarMoeda(totalDebitos),
        totalCreditos: formatarMoeda(totalCreditos),
    }
    return element;

}

function formatarMoeda(valor) {
    valor = parseFloat(valor / 100).toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
    });
    return valor;
}

function formatarData(secs) {
    const date = moment(secs);

    const data = date.format('DD/MM/YYYY');

    let minutes = date.minutes() < 10 ? '0' + date.minutes() : date.minutes();

    const hora = date.hour() < 10 ? '0' + date.hour() : date.hour();
    return {
        data: data,
        hora: hora + ':' + minutes,
    }
}