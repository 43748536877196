import { currentUser } from '../../auth/auth-provider';
import auditoria from '../../auditoria/auditoria';

export async function salvarAuditoriaAtivo(newData, oldData, ownerId) {
    const { displayName, uid } = currentUser();

    const { cpf, email } = newData;
    const { ativo, key, nome } = oldData;

    const collection = 'beneficiarios';
    const descricao = `O usuário ${displayName} trocou o estado do beneficiário ${nome}, de cpf '${cpf}' ${email ? ` e email '${email}'` : ''}, para ${!ativo ? 'ativo' : 'inativo'}`;
    const tipo = 'Troca de estado de beneficiário';

    auditoria.post(
        ownerId,
        uid,
        displayName,
        key,
        collection,
        descricao,
        tipo
    );
}

export async function salvarAuditoriaBloqueado(newData, oldData, ownerId) {
    const { displayName, uid } = currentUser();

    const { bloqueado, cpf, email } = newData;
    const { key, nome } = oldData;

    const collection = 'beneficiarios';
    const descricao = `O usuário ${displayName} ${bloqueado ? 'bloqueou' : 'desbloqueou'} o beneficiário ${nome}, de cpf '${cpf}' ${email ? ` e email '${email}'` : ''}`;
    const tipo = `${bloqueado ? 'Bloqueio' : 'Desbloqueio'} de beneficiário`;

    auditoria.post(
        ownerId,
        uid,
        displayName,
        key,
        collection,
        descricao,
        tipo
    );
}

export async function salvarAuditoriaCreditoZerado(newData, oldData, ownerId) {
    const { displayName, uid } = currentUser();

    const { cpf, email } = newData;
    const { key, nome } = oldData;

    const collection = 'beneficiarios';
    const descricao = `O usuário ${displayName} zerou o crédito pré-pago do beneficiário ${nome}, de cpf '${cpf}' ${email ? ` e email '${email}'` : ''}`;
    const tipo = 'Crédito pré-pago zerado';

    auditoria.post(
        ownerId,
        uid,
        displayName,
        key,
        collection,
        descricao,
        tipo
    );
}