
import React from 'react';
import {
  Form,
  Input,
  Tooltip,
  Button,
  Divider,
  Icon,
  Row,
  Col,
  Switch,
  Tabs,
} from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { fbDatabase } from '../../../firebase/firebase';
import { getOwnerId } from '../../auth/auth-provider';
import { isCNPJ, formatCNPJ } from '../../funcs/utils';
import mensagem from '../../messages/message';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CL_ESTABELECIMENTOS, ESTABELECIMENTO_NOVA_CHAVE_API } from '../../funcs/constants';


const { TabPane } = Tabs;


class EstabelecimentoCRUD extends React.Component {
  state = {
    confirmDirty: false,
    autoCompleteResult: [],
    data: {
      key: this.props.record.key,
      razaoSocial: '',
      nomeFantasia: '',
      cnpj: '',
      email: '',
      endereco: {
        logradouro: '',
        bairro: '',
        cidade: '',
        uf: '',
        telefone: '',
      },
    },
    integracao: {
      ativo: false,
      key: ''
    }
  };

  constructor(props) {
    super(props);
    this.cancelClick = this.cancelClick.bind(this);
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (err) {
        return;
      }

      if (!isCNPJ(values.cnpj)) {
        mensagem.openNotificationWithIcon('error', 'CNPJ inválido', 'O CNPJ informado não está correto.');
        return;
      }
      values.cnpj = formatCNPJ(values.cnpj);


      // procuro pelo CNPJ para checar se está repetido
      let snapshot = await fbDatabase.collection(CL_ESTABELECIMENTOS)
        .where('cnpj', '==', values.cnpj)
        .get();

      // Verifico se está havendo uma duplicação de CNPJ
      if (!snapshot.empty) {
        if ((this.props.editMode && snapshot.docs[0].id !== this.props.record.key) ||
          (this.props.editMode && snapshot.docs.length > 1) || (!this.props.editMode)) {
          mensagem.openNotificationWithIcon('error', 'Duplicação', 'O CNPJ informado já foi cadastrado.');
          return;
        }
      }

      // procuro pelo Email para checar se está repetido
      snapshot = await fbDatabase.collection(CL_ESTABELECIMENTOS)
        .where('email', '==', values.email)
        .get();

      if (!snapshot.empty) {
        if ((this.props.editMode && snapshot.docs[0].id !== this.props.record.key) ||
          (this.props.editMode && snapshot.docs.length > 1) || (!this.props.editMode)) {
          mensagem.openNotificationWithIcon('error', 'Duplicação', 'O email informado já foi cadastrado.');
          return;
        }
      }

      const ownerId = await getOwnerId();

      const item = {
        razaoSocial: values.razaoSocial,
        nomeFantasia: values.nomeFantasia,
        cnpj: values.cnpj,
        email: values.email,
        endereco: {
          logradouro: values.endereco,
          bairro: values.bairro,
          cidade: values.cidade,
          uf: values.uf,
          telefone: values.telefone
        },
        ownerId,
        integracao: this.state.data.integracao,
      }

      let novoId = '';
      if (!this.props.editMode) {
        await fbDatabase.collection(CL_ESTABELECIMENTOS).add({
          ...item
        })
          .then((res) => {
            novoId = res.id;
          });
      }
      else {
        fbDatabase.collection(CL_ESTABELECIMENTOS).doc(this.props.record.key).update({
          ...item
        });
      }

      const tipoAtualizacao = this.props.editMode ? 'atualizado' : 'cadastrado';
      mensagem.openNotificationWithIcon('success', 'Perfeito!', `O estabelecimento ${values.razaoSocial} foi ${tipoAtualizacao} com sucesso`, 3);
      item['key'] = novoId ? novoId : this.props.record.key;
      this.props.handleOk(item);
    });
  };

  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  componentDidMount() {
    if (this.props.editMode && this.props.record) {
      this.setState({
        data: {
          key: this.props.record.key,
          razaoSocial: this.props.record.razaoSocial,
          nomeFantasia: this.props.record.nomeFantasia,
          cnpj: this.props.record.cnpj,
          email: this.props.record.email,
          endereco: this.props.record.endereco,
          integracao: this.props.record.integracao
        }
      });
    }
  }

  uuidSubstring() {
    let uuid = uuidv4();
    let key = uuid.substr(24);

    return key;
  }

  newAPIKey() {
    debugger;
    let isOk = true;
    if (this.state.integracao.key !== '') {
      isOk = mensagem.confirmar(ESTABELECIMENTO_NOVA_CHAVE_API);

    }

    if (!isOk) {
      return;
    }

    let key = this.uuidSubstring();

    this.setState({
      apiKeyCopiado: false,
      data: {
        ...this.state.data,
        integracao: {
          key: key,
          ativo: this.state.data.integracao ? this.state.data.integracao.ativo : false
        }
      }
    });
  }

  ativacaoAPIKey(ativo) {
    debugger;
    let key;

    if (!this.state.data.integracao) {
      key = this.uuidSubstring();
    } else {
      key = this.state.data.integracao.key
    }

    this.setState({
      data: {
        ...this.state.data,
        integracao: {
          key: key,
          ativo: ativo
        }
      }
    });
  }

  cancelClick() {
    this.props.handleCancel();
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };

    return (
      <Form {...formItemLayout} onSubmit={this.handleSubmit}>

        <Tabs defaultActiveKey="geral">

          <TabPane tab="Geral" key="geral">

            <Form.Item
              label={
                <span>
                  CNPJ&nbsp;
                  <Tooltip title="Qual é o CNPJ?">
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </span>
              }
            >
              {getFieldDecorator('cnpj', {
                initialValue: this.state.data.cnpj,
                rules: [{ required: true, message: 'Informe o CNJP', whitespace: false }],
              })(<Input />)}
            </Form.Item>

            <Form.Item
              ref='txtRazaoSocial'
              label={
                <span>
                  Razão Social&nbsp;
                  <Tooltip title="Qual é a Razão Social?">
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </span>
              }
            >
              {getFieldDecorator('razaoSocial', {
                initialValue: this.state.data.razaoSocial,
                rules: [{ required: true, message: 'Informe a Razão Social', whitespace: true }],
              })(<Input />)}
            </Form.Item>

            <Form.Item
              label={
                <span>
                  Nome Fantasia&nbsp;
                  <Tooltip title="Qual é o Nome Fantasia?">
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </span>
              }
            >
              {getFieldDecorator('nomeFantasia', {
                initialValue: this.state.data.nomeFantasia,
                rules: [{ required: true, message: 'Informe o Nome Fantasia', whitespace: true }],
              })(<Input />)}
            </Form.Item>

            <Form.Item label="E-mail" ref="txtEmail">
              {getFieldDecorator('email', {
                initialValue: this.state.data.email,
                rules: [
                  {
                    type: 'email',
                    message: 'Email não é válido',
                  },
                  {
                    required: true,
                    message: 'Informe o email',
                  },
                ],
              })(<Input />)}
            </Form.Item>

            <Form.Item label="Chave Integração" extra={this.state.apiKeyCopiado ? "Chave da API copiado." : null}>
              {getFieldDecorator('integracao', {
              })(
                <Row gutter={8}>
                  <Col span={16}>
                    <Input placeholder="Chave Integração"
                      value={this.state.data.integracao ? this.state.data.integracao.key : "- - - - - - -"}
                      addonBefore={
                        this.state.data.integracao ?
                          <Icon type="reload" onClick={() => { this.newAPIKey() }} /> :
                          <Icon type="plus" onClick={() => { this.newAPIKey() }} />
                      }
                      addonAfter={
                        this.state.data.integracao ?
                          <CopyToClipboard text={this.state.data.integracao.key} onCopy={() => this.setState({ apiKeyCopiado: true })}>
                            <Icon type="copy" />
                          </CopyToClipboard> :
                          null
                      } />
                  </Col>
                  <Col span={8}>
                    <Switch
                      checked={this.state.data.integracao ? this.state.data.integracao.ativo : false}
                      checkedChildren={<span>Ativo</span>}
                      unCheckedChildren={<span>Inativo</span>}
                      onChange={(ativo, event) => this.ativacaoAPIKey(ativo)}
                    />
                  </Col>
                </Row>)}
            </Form.Item>
            <Form.Item label="Telefone">
              {getFieldDecorator('telefone', {
                initialValue: this.state.data.endereco.telefone,
                rules: [{ required: true, message: 'Informe o telefone' }],
              })(<Input style={{ width: '100%' }} />)}
            </Form.Item>

          </TabPane>

          <TabPane tab="Endereço" key="endereco">

            <Form.Item label="Endereço">
              {getFieldDecorator('endereco', {
                initialValue: this.state.data.endereco.logradouro,
                rules: [{ required: true, message: 'Informe o endereço' }],
              })(<Input style={{ width: '100%' }} />)}
            </Form.Item>

            <Form.Item label="Bairro">
              {getFieldDecorator('bairro', {
                initialValue: this.state.data.endereco.bairro,
                rules: [{ required: true, message: 'Informe o bairro' }],
              })(<Input style={{ width: '50%' }} />)}
            </Form.Item>

            <Form.Item label="Cidade">
              {getFieldDecorator('cidade', {
                initialValue: this.state.data.endereco.cidade,
                rules: [{ required: true, message: 'Informe a cidade' }],
              })(<Input style={{ width: '70%' }} />)}
            </Form.Item>

            <Form.Item label="UF">
              {getFieldDecorator('uf', {
                initialValue: this.state.data.endereco.uf,
                rules: [{ required: true, message: 'Informe a UF' }],
              })(<Input style={{ width: '20%' }} />)}
            </Form.Item>

          </TabPane>
        </Tabs>

        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit">
            Salvar
          </Button>
          <Divider type="vertical" />
          <Button onClick={this.cancelClick}>
            Cancelar
          </Button>

        </Form.Item>
      </Form>
    );
  }
}

export default Form.create()(EstabelecimentoCRUD);