import React from 'react';
import { withRouter } from 'react-router';
import { Select, Button, message, Card, Table, Tooltip, Icon } from 'antd';
import { getConvenios, getBeneficiarios, getLastPurchase } from './EstornoF';
import { currentUser, currentUserCol, isAdministrador } from '../../components/auth/auth-provider';
import { estornoDebito } from '../../components/gerenciaNet/gerencia-net-provider';

import PageLayout from '../../components/layout/PageLayout';
import mensagem from '../../components/messages/message';
import history from '../../history';
import GerenciaNet from '../../components/gerenciaNet/gerencia-net-provider';

import './Estorno.css'


const { Option } = Select


class Estornos extends React.Component {
    state = {
        movimento: {},
        loading: true,
        convenioId: '',
        beneficiario: {},
        cardSaldo: '--',
        tableData: [],
    }

    options = [];

    constructor(props) {
        super(props)
        this.onSelectChange = this.onSelectChange.bind(this);
        this.onFilterClick = this.onFilterClick.bind(this);
        this.onSelectBeneficiarioChange = this.onSelectBeneficiarioChange.bind(this);
        this.buildColums = this.buildColums.bind(this);
        this.formatarMoeda = this.formatarMoeda.bind(this);
        this.estornar = this.estornar.bind(this);
    }

    async componentDidMount() {
        this.setState({ loading: false });

        const userCol = currentUserCol();
       

        if (await isAdministrador() && userCol) {
            const isOk = userCol.permissoes && userCol.permissoes.some(function (v) { return v.indexOf("estornoDebito") >= 0 });

            if (!isOk) {
                this.setState({ loading: false });
                await mensagem.avisar('Você não tem permisão para acessar essa página.');
                history.push('/dashboard');
                history.go();
                return;
            }
        }

        const convenios = await getConvenios();

        if (!convenios) {
            return;
        }

        for (const item of convenios) {
            this.options.push(<Option key={item.key} value={item.key}>{item.nomeFantasia}</Option>)
        }
    }

    async onSelectChange(value) {
        this.beneficiariosOptions = [];

        const b = await getBeneficiarios(value);
        let saldos = {};

        for (const item of b) {
            saldos[item.key] = item.saldo ? (item.saldo / 100) : item.operacao ? (item.operacao.saldo / 100) : 0;
            this.beneficiariosOptions.push(<Option key={item.key} value={item.key}>{item.nome + ' ' + item.sobrenome} </Option>)
        }

        this.setState({ convenioId: value, alterouConfiguracaoRelatorio: true, beneficiario: {}, beneficiarioSaldos: saldos })
    }

    onSelectBeneficiarioChange(value, object) {
        const beneficiario = {
            id: value,
            nome: object.props.children
        }
        this.setState({
            beneficiario,
            data: [],
            cardSaldo: '',
        })
    }


    async onFilterClick() {

        this.buildColums();

        if (this.state.convenioId === '') {
            message.warning("Selecione um convênio");
            return;
        }

        if (!this.state.beneficiario.id) {
            message.warning("Selecione um beneficiário");
            return;
        }

        this.setState({ loading: true });

        const data = await getLastPurchase(this.state.beneficiario.id);
        if (!data) {
            mensagem.openNotificationWithIcon('error', 'Erro', 'Não há movimento de débito elegível a ser estornado para esta pessoa');
            this.setState({ loading: false });
            return;
        }
        const row = {
            key: 'lastPurchase',
            estabelecimento: data.autorizacao.estabelecimento,
            data: data.data.toDate().toLocaleString(),
            debitoVia: data.debitoVia === 'aplicativo' ? 'Via Aplicativo' : 'Via Cartão',
            autorizacaoParaCancelamento: data.autorizacaoParaCancelamento,
            debito: this.formatarMoeda(data.debito / 100),
        }
        const tableData = [];
        tableData.push(row);
        this.setState({
            tableData,
            cardSaldo: this.formatarMoeda(data.saldo / 100),
            loading: false,
            showBeneficiarios: true,
            movimento: data,
        });
    }

    formatarMoeda(valor) {
        valor = parseFloat(valor).toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
        });
        return valor;
    }

    async estornar() {
        if (this.state.loading) return;

        var dezDias = new Date().getTime() - (10 * 24 * 60 * 60 * 1000);
        const dataDebito = this.state.movimento.data.toDate().getTime();
        if (dataDebito < dezDias) {
            mensagem.openNotificationWithIcon('error', 'Erro', 'Estorno não autorizado, pois ocorreu há mais de dez dias. (Verifique regras de estorno impressas na página)');
            return;
        }

        const podeEstornar = await mensagem.confirmar('Deseja mesmo estornar este débito?');
        if (!podeEstornar) {
            return
        }

        this.setState({ loading: true });

        const gerencia = new GerenciaNet();
        const data = await gerencia.getToken(this.state.convenioId);

        const user = await currentUser();
        estornoDebito(
            this.state.movimento.autorizacao.estabelecimento.key,
            this.state.movimento.autorizacaoParaCancelamento,
            this.state.movimento.debito,
            data.token,
            user.displayName ? user.displayName : user.email)
            .then(res => {
                mensagem.openNotificationWithIcon('success', 'Ok!', 'Estorno realizado com sucesso');

                const tableData = [];
                this.setState({
                    tableData,
                    cardSaldo: '--',
                    loading: false,
                    movimento: [],
                });
            })
            .catch(err => {
                console.log(err);
                mensagem.openNotificationWithIcon('error', 'Erro', 'Não foi possível realizar este estorno');
                this.setState({ loading: false });
            });
    }

    buildColums() {
        const columns = [
            {
                title: 'Estabelecimento',
                dataIndex: 'estabelecimento.nomeFantasia',
                key: 'estabelecimento.nomeFantasia'
            },
            {
                title: 'Forma pagto',
                dataIndex: 'debitoVia',
                key: 'debitoVia'
            },
            {
                title: 'Data',
                dataIndex: 'data',
                key: 'data'
            },
            {
                title: 'Valor',
                dataIndex: 'debito',
                key: 'debito'
            },
            {
                title: 'Autorização de Cancelamento',
                dataIndex: 'autorizacaoParaCancelamento',
                key: 'autorizacaoParaCancelamento'
            }, {
                title: '',
                key: 'action',
                render: (text, record) => (
                    <span>
                        <Tooltip placement="topLeft" title="Estornar">
                            <Icon
                                type="rollback"
                                onClick={() => this.estornar(record)}
                            />
                        </Tooltip>


                    </span>
                ),
            },

        ]
        this.setState({ columns })
    }


    render() {
        return (
            <div>
                <PageLayout selectItem={"estorno-debito"}>
                    <div className="containerMovimentacao">
                        <label>Convênio: </label>
                        <Select

                            showSearch
                            filterOption={(input, option) =>
                                option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                            disabled={this.state.loading}
                            optionFilterProp="children"
                            style={{ width: '400px' }}
                            placeholder='Selecione um convênio'
                            onChange={this.onSelectChange}
                            value={this.state.convenioId}
                        >{this.options}</Select>

                        <label style={{ marginLeft: 10, marginRight: 10 }}>Beneficiário: </label>
                        <Select
                            ref={(el) => this.beneficiarioRef = el}
                            disabled={this.state.loading}
                            showSearch
                            optionFilterProp="children"
                            style={{ width: '20%' }}
                            placeholder='Selecione um beneficiário'
                            onChange={this.onSelectBeneficiarioChange}
                            value={this.state.beneficiario.id}
                        >
                            {this.beneficiariosOptions}
                        </Select>

                        <Button loading={this.state.loading} onClick={this.onFilterClick} type="primary">Localizar</Button>
                    </div>

                    <div className="labelNomeBeneficiario">
                        <label>{this.state.beneficiario.nome ? this.state.beneficiario.nome : ''}</label>
                    </div>

                    <div className='Cards'>
                        <Card
                            title="Saldo Atual"
                            style={{ width: 300 }}
                        >
                            {this.state.cardSaldo}
                        </Card>
                    </div>
                    <p className='subtituloEstornosParagrafoRegra'><i>Regras de estorno:</i></p>
                    <p className='subtituloEstornosParagrafoItemRegra'>1º Você só pode estornar o último débito realizado pela pessoa selecionada;</p>
                    <p className='subtituloEstornosParagrafoItemRegra'>2º O débito buscado deverá ser o último movimento do beneficiário (Exemplo: Se o último movimento for de crédito, não retornaremos nenhum registro. O objetivo é inibir estornos de débitos antigos);</p>
                    <p className='subtituloEstornosParagrafoItemRegra'>3º O estorno não será realizado se tiver ocorrido há mais de 10 dias;</p>
                    <p className='subtituloEstornosParagrafoItemRegra'>4º O beneficiário será notificado via aplicativo ou email do estorno e o motivo;</p>
                    <p className='subtituloEstornosParagrafoItemRegra'>5º O seu gestor de contas será notificado.</p>
                    <label className='subtituloEstornos'>Último débito: </label>
                    <div className="tableEstabelecimentos">
                        <Table
                            columns={this.state.columns}
                            dataSource={this.state.tableData}
                        >
                        </Table>
                    </div>
                </PageLayout>
            </div>
        )
    }

}

export default withRouter(Estornos);

