import React from 'react';
import { withRouter } from 'react-router'
import { Table, Button, Icon, Modal, Input, Tooltip } from 'antd';
import {
    getOwnerId,
    getUserType,
    currentUserCol,
    isAdministrador,
    isGestor,
    isDebitPresent,
    isCashbackPresent,
    isLoyaltyPresent
} from '../../components/auth/auth-provider';

import ConvenioCRUD from '../../components/cruds/convenios/ConvenioCRUD';
import PageLayout from '../../components/layout/PageLayout';
import history from '../../history';
import mensagem from '../../components/messages/message';
import convenioDB from '../../dataManager/dtmConvenio';
import administradorDB from '../../dataManager/dtmAdministrador';
import listObj from '../../components/listSearch/listSearch';
import vertteStorage from '../../components/localstorage/localstorage';

import './Convenio.css';

const { Search } = Input;


class Convenio extends React.Component {
    state = {
        administradores: [],
        convenios: [],
        conveniosOriginal: [],
        isSearching: false,
        loading: true,
        userType: 'Gerente',
        debitPresent: undefined,
        cashbackPresent: undefined,
        loyaltyPresent: undefined,
        permissoes: {
            naoCadastrar: false,
            naoEditar: false
        }
    }

    constructor(props) {
        super(props);
        this.updateList = this.updateList.bind(this);
        this.applyFilter = this.applyFilter.bind(this);
        this.headerConvenioElement = React.createRef();
    }

    async componentDidMount() {
        const userType = await getUserType();
        const ownerId = await getOwnerId();

        let naoCadastrar = false;
        let naoEditar = false;

        const userCol = currentUserCol();

        // Se o tipo do usuário for administrador, não preciso verificar se ele tem
        // Permissão ou não para acessar a página. Ele tem!
        if (!await isAdministrador() && userCol) {
            const isOk = userCol.permissoes.some(function (v) { return v.indexOf("convenios") >= 0 });

            if (!isOk) {
                this.setState({ loading: false });
                await mensagem.avisar('Você não tem permisão para acessar essa página.');
                history.push('/dashboard');
                history.go();
                return;
            }

            naoCadastrar = !userCol.permissoes.some(function (v) { return v.indexOf("convenios-cadastrar") >= 0 });

            if (naoCadastrar) {
                naoCadastrar = userCol.permissoes.indexOf('convenios') === -1;
            }

            naoEditar = !userCol.permissoes.some(function (v) { return v.indexOf("convenios-editar") >= 0 });

            if (naoEditar) {
                naoEditar = userCol.permissoes.indexOf('convenios') === -1;
            }
        }

        let administradores = undefined;
        let convenios = undefined;

        // Regra de Acesso para Administradores: 
        // 1 - Pego apenas o administrador que está logado;
        // 2 - Filtro apenas os convênios do administrador logado.

        if (await isAdministrador()) {
            const administrador = await administradorDB.getById(userCol.key);

            if (administrador) {
                administradores = [];
                administradores.push(administrador);
            }

            convenios = await convenioDB.getByOwnerIdAndAdminId(ownerId, userCol.key, true, true);
        } else if (await isGestor()) {
            administradores = await administradorDB.getByOwnerIdCustom(ownerId);
            convenios = await convenioDB.getByOwnerIdCustom(ownerId, true, true);
        } else {
            const administrador = await administradorDB.getById(userCol.administrador.id);

            if (administrador) {
                administradores = [];
                administradores.push(administrador);
            }

            convenios = await convenioDB.getByOwnerIdAndAdminId(ownerId, userCol.administrador.id, true, true);
        }

        const debitPresent = await isDebitPresent();
        const cashbackPresent = await isCashbackPresent();
        const loyaltyPresent = await isLoyaltyPresent();

        this.setState({
            userType,
            administradores,
            convenios,
            debitPresent,
            cashbackPresent,
            loyaltyPresent,
            conveniosOriginal: convenios,
            permissoes: { naoCadastrar, naoEditar },
            loading: false
        });

    }

    updateList(record) {
        record['prazoStr'] = record.gerarCobranca === true ? record.prazo : '--';
        record['gerarCobrancaStr'] = record.gerarCobranca === true ? 'Sim' : 'Não';

        let convenios = this.state.convenios;
        let registroEncontrado = false;

        this.state.convenios.forEach((item, index) => {
            if (item.key === record.key) {
                convenios[index] = record;
                registroEncontrado = true;
            }
        });

        if (!registroEncontrado) {
            convenios.push(record);
        }

        if (this.state.isSearching) {
            let conveniosOriginal = this.state.conveniosOriginal;
            registroEncontrado = false;

            this.state.conveniosOriginal.forEach((item, index) => {
                if (item.key === record.key) {
                    conveniosOriginal[index] = record;
                    registroEncontrado = true;
                }
            });

            if (!registroEncontrado) {
                conveniosOriginal.push(record);
            }

            this.setState({ convenios, conveniosOriginal });
        } else {
            this.setState({ convenios, conveniosOriginal: convenios });
        }

        vertteStorage.clear('@firebase-cache/update/convenioDB/getByOwnerIdCustom');
        vertteStorage.clear('@firebase-cache/update/convenioDB/getByOwnerIdAndAdminId');
    }

    async applyFilter(text) {
        const { conveniosOriginal } = this.state;

        if (text === '') {
            this.setState({
                convenios: conveniosOriginal,
                isSearching: false
            });

            return;
        };

        const keys = [
            'cnpj',
            'razaoSocial',
            'nomeFantasia',
            'administrador.nome',
            'prazo',
            'ativoStr'
        ];

        const convenios = listObj.search(conveniosOriginal, text, keys);

        this.setState({
            convenios,
            isSearching: true
        });
    }

    handleCancel = () => {
        this.setState({ visible: false });
    };

    editarConvenioElement(record) {
        if (this.state.permissoes.naoEditar) return;
        this.headerConvenioElement.current.editarConvenio(record);
    }

    render() {
        const columns = [
            {
                title: 'CNPJ',
                dataIndex: 'cnpj',
                key: 'cnpj',
            },
            {
                title: 'Razão Social',
                dataIndex: 'razaoSocial',
                key: 'razaoSocial',
                editable: false,
            },
            {
                title: 'Nome Fantasia',
                dataIndex: 'nomeFantasia',
                key: 'nomeFantasia',
            },
            {
                title: "Administrador",
                dataIndex: "administrador.nome",
                key: "empresaNome"
            },
            {
                title: "Ativo",
                dataIndex: "ativoStr",
                key: "ativoStr"
            },

        ];

        if (this.state.debitPresent) {
            columns.push({
                title: 'Gerar Cobrança',
                dataIndex: 'gerarCobrancaStr',
                key: 'gerarCobrancaStr',
            });

            columns.push({
                title: 'Dias p/Pagto',
                dataIndex: 'prazoStr',
                key: 'prazoStr',
            });
            columns.push({
                title: 'Ativo',
                dataIndex: 'ativoStr',
                key: 'ativoStr',
            });
        }

        if (!this.state.permissoes.naoEditar && (this.state.userType !== 'Administrador' && this.state.userType !== 'AdministradorUsuario')) {
            columns.push({
                title: '',
                width: 'auto',
                key: 'action',
                render: (text, record) => (
                    <span>
                        <Tooltip placement="topLeft" title="Editar Cadastro">
                            <Icon
                                type="edit"
                                onClick={() => this.editarConvenioElement(record)}
                            />
                        </Tooltip>
                    </span>
                ),
            });
        }

        return (
            <div className="divTable">
                <PageLayout selectItem={'convenios'}>
                    <Table
                        pagination={{
                            defaultPageSize: 100,
                            position: 'both',
                            pageSizeOptions: ["50", "100", "150", "200", "250", "300"],
                            showSizeChanger: true,
                            locale: { items_per_page: "" }
                        }}
                        title={() =>
                            <HeaderConvenio
                                administradores={this.state.administradores}
                                naoCadastrar={this.state.permissoes.naoCadastrar}
                                ref={this.headerConvenioElement}
                                updateList={this.updateList}
                                applyFilter={this.applyFilter}
                                userType={this.state.userType} />
                        }
                        columns={columns}
                        dataSource={this.state.convenios}
                        loading={this.state.loading}
                        bordered
                    />
                </PageLayout>
            </div>
        );
    }
}

class HeaderConvenio extends React.Component {

    state = {
        visible: false
    }

    constructor(props) {
        super(props);
        this.novoConvenioElement = React.createRef();
        this.filterTable = this.filterTable.bind(this);
        this.novoConvenio = this.novoConvenio.bind(this);
    }

    novoConvenio() {
        const record = [];
        record["administradores"] = this.props.administradores;
        this.novoConvenioElement.current.show(false, record);
    }

    editarConvenio(record) {
        record["administradores"] = this.props.administradores;
        this.novoConvenioElement.current.show(true, record);
    }

    filterTable(value) {
        const text = typeof value === "object" ? value.target.value : value;
        this.props.applyFilter(text);
    }

    render() {

        const titulo = window.location.pathname === '/lojas' ? 'Nova Loja' : 'Novo Convênio';

        return (
            <div>
                <NovoConvenio ref={this.novoConvenioElement} updateList={this.props.updateList}></NovoConvenio>
                {this.props.userType === 'Administrador' || this.props.userType === 'AdministradorUsuario' ?
                    ''
                    :
                    <Button disabled={this.props.naoCadastrar} type="primary" onClick={this.novoConvenio}><Icon className="icon" type="plus" />{titulo}</Button>
                }

                <Search
                    placeholder="Procurar"
                    onSearch={this.filterTable}
                    onChange={this.filterTable}
                    style={{ marginLeft: "10px", width: 200 }}
                />

            </div>
        );
    }
}

class NovoConvenio extends React.Component {
    state = {
        visible: false,
        confirmLoading: false,
        editMode: false,
        record: []
    }

    constructor(props) {
        super(props);
        this.handleOk = this.handleOk.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    show(editMode, record) {
        if (!record) {
            record = [];
        }

        this.setState({
            visible: true,
            confirmLoading: false,
            editMode: editMode,
            record: record
        });
    }

    handleOk(record) {
        this.setState({ visible: false });
        this.props.updateList(record);
    }

    handleCancel() {
        this.setState({ visible: false });
    }

    render() {
        const titulo = window.location.pathname === '/lojas' ? 'Loja' : 'Convênio';
        return (
            <Modal
                title={titulo}
                visible={this.state.visible}
                destroyOnClose={true}
                confirmLoading={this.state.confirmLoading}
                centered={true}
                onOk={this.handleOk}
                footer={null}
                closable={true}
                width="650px"
                onCancel={this.handleCancel}

            >
                <ConvenioCRUD handleOk={this.handleOk} handleCancel={this.handleCancel} editMode={this.state.editMode} record={this.state.record}></ConvenioCRUD>

            </Modal>
        );
    }
}

export default withRouter(Convenio);