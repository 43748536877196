import React from 'react';
import { withRouter } from 'react-router';
import PageLayout from '../../components/layout/PageLayout';
import { Select, Button, message, DatePicker, Steps, Spin, Icon, Progress, Result } from 'antd';
import { getConvenios, getBeneficiarios, getEstabelecimentos, getSaldo } from './CapturaF';
import './Captura.css'
import { currentUserCol, isAdministrador } from '../../components/auth/auth-provider';
import mensagem from '../../components/messages/message';
import history from '../../history';
import CurrencyInput from 'react-currency-input';
import moment from 'moment';
import API from '../../components/api/api';
import { dateDiffInDays } from '../../components/funcs/utils';


const key = 'messageCapture';
const { Option } = Select
const { Step } = Steps;
const dateFormat = 'DD/MM/YYYY - HH:mm';
const antIcon = <Icon type="loading" style={{ fontSize: 48 }} spin />;


class Captura extends React.Component {
    state = {
        loading: true,
        step: 0,
        resultType: 'success',
        resultText: 'Débito efetuado com sucesso',
        resultTextSubTitle: 'Saldo do cliente é de R$ 0,00',

        progress: {
            percent: 0,
            format: '',
        },

        descriptions: {
            location: '',
            whoIs: '',
        },

        estabelecimentos: [],
        convenios: [],

        payment: {
            estabelecimento: {},
            convenio: {},
            beneficiario: {},
            data: undefined,
            valor: 'R$ 0,00',
            valorEmCentavos: 0,
        },
        paymentReturn: {

        },
        saldo: 'R$ 0,00',
    }

    conveniosOptions = [];
    estabelecimentosOptions = [];

    constructor(props) {
        super(props)

        this.btnStart = this.btnStart.bind(this);
        this.btnPrior = this.btnPrior.bind(this);
        this.btnNext = this.btnNext.bind(this);
        this.onEstabelecimentoChange = this.onEstabelecimentoChange.bind(this);
        this.onConvenioChange = this.onConvenioChange.bind(this);
        this.onBeneficiarioChange = this.onBeneficiarioChange.bind(this);
        this.onDataChange = this.onDataChange.bind(this);
        this.disabledDate = this.disabledDate.bind(this);
        this.onValorChange = this.onValorChange.bind(this);
        this.doCapture = this.doCapture.bind(this);
        this.formatarMoeda = this.formatarMoeda.bind(this);
    }

    async componentDidMount() {
        const userCol = currentUserCol();

        // Se o tipo do usuário for administrador, não preciso verificar se ele tem
        // Permissão ou não para acessar a página. Ele tem!
        if (!await isAdministrador() && userCol) {
            const isOk = userCol.permissoes.some(function (v) { return v.indexOf("capturaDebito") >= 0 });
            if (!isOk) {
                this.setState({ loading: false });
                await mensagem.avisar('Você não tem permisão para acessar essa página.');
                history.push('/dashboard');
                history.go();
                return;
            }
        }

        const estabelecimentos = await getEstabelecimentos();
        if (!estabelecimentos) {
            return;
        }

        this.setState({ estabelecimentos });

        for (const item of estabelecimentos) {
            this.estabelecimentosOptions.push(<Option key={item.key} value={item.key}>{item.nomeFantasia}</Option>)
        }

        const convenios = await getConvenios();
        if (!convenios) {
            return;
        }

        for (const item of convenios) {
            this.conveniosOptions.push(<Option key={item.key} value={item.key}>{item.nomeFantasia}</Option>)
        }

        this.setState({ convenios, loading: false });
    }

    btnStart() {
        const step = this.state.step = 0;
        const progress = {
            percent: 0,
            format: '',
        };

        const descriptions = {
            location: '',
            whoIs: '',
        };

        const payment = {
            estabelecimento: {},
            convenio: {},
            beneficiario: {},
            data: undefined,
            valor: 'R$ 0,00',
            valorEmCentavos: 0,
        };

        const paymentReturn = {

        };
        const saldo = 'R$ 0,00';

        this.setState({ progress, descriptions, payment, paymentReturn, saldo, step });
    }

    btnPrior() {
        const step = this.state.step - 1;
        this.setState({ step });
    }

    async btnNext() {
        if (this.state.step === 0 && this.state.payment.estabelecimento.key === '') {
            message.error({ content: 'Informe o estabelecimento', key });
            return;
        }

        if (this.state.step === 1 && !this.state.payment.convenio.key) {
            message.error({ content: 'Informe o convênio', key });
            return;
        }

        if (this.state.step === 1 && !this.state.payment.beneficiario.id) {
            message.error({ content: 'Informe o beneficiário', key });
            return;
        }

        if (this.state.step === 1 && !this.state.payment.data) {
            message.error({ content: 'Informe a data', key });
            return;
        }

        if (this.state.step === 1 && !this.state.payment.valorEmCentavos) {
            message.error({ content: 'Informe o valor', key });
            return;
        }

        if (this.state.step === 1) {

            debugger;
            const valorDebito = this.formatarMoeda(this.state.payment.valorEmCentavos / 100);
            const nome = this.state.payment.beneficiario.nome;
            const data = this.state.payment.data._d.toLocaleDateString();
            const hora = this.state.payment.data._d.toLocaleTimeString()

            const msg = `Confirma debitar o valor de ${valorDebito} de ${nome} em ${data} às ${hora}h?`;
            let isOk = await mensagem.confirmar(msg);
            if (!isOk) {
                this.setState({ loading: false });
                return;
            }

            this.doCapture();
        }

        const step = this.state.step + 1;
        this.setState({ step });
    }

    async onEstabelecimentoChange(value) {
        const descriptions = this.state.descriptions;

        const estabelecimento = this.state.estabelecimentos.filter((item) => {
            return item.key === value
        })[0];

        const payment = this.state.payment;
        payment.estabelecimento = estabelecimento;

        descriptions.location = estabelecimento.nomeFantasia;
        this.setState({ descriptions, payment, step: this.state.step + 1 });
    }

    async onConvenioChange(value) {
        this.setState({ loading: true });
        this.beneficiariosOptions = [];
        const b = await getBeneficiarios(value);
        for (const item of b) {
            this.beneficiariosOptions.push(<Option key={item.key} value={item.key}>{item.nome + ' ' + item.sobrenome} </Option>)
        }

        const convenio = this.state.convenios.filter((item) => {
            return item.key === value
        })[0];

        const payment = this.state.payment;
        payment.beneficiario = {};
        payment.convenio = convenio;
        this.setState({ payment, loading: false, });
    }

    async onBeneficiarioChange(value, object) {
        this.setState({ loading: true });
        const payment = this.state.payment;
        payment.beneficiario = {
            id: value,
            nome: object.props.children
        }

        const descriptions = this.state.descriptions;
        descriptions.whoIs = payment.beneficiario.nome;

        let saldo = await getSaldo(payment.beneficiario.id);
        saldo = this.formatarMoeda(saldo / 100);
        this.setState({
            payment,
            data: [],
            cardSaldo: '',
            descriptions,
            saldo,
            loading: false,
        })
    }

    onDataChange(value) {
        const payment = this.state.payment;
        payment.data = value;
        this.setState({ payment });
    }

    disabledDate(current) {
        // Limite fixado em 10 dias. De hoje pra trás. E pro futuro, nunca. 
        const endOf = moment().endOf('day');
        const date = new Date();
        const past10Days = moment(date).subtract(10, 'day');
        return (current && current <= past10Days) || (current && current > endOf);
    }

    onValorChange(value) {
        const payment = this.state.payment;
        payment.valor = value;


        let valorEmCentavos = Number(payment.valor.replace('R$ ', '').split('.').join('').split(',').join('.'));
        payment.valorEmCentavos = valorEmCentavos * 100;
        this.setState({ payment });
    }

    async doCapture() {

        const progress = this.state.progress;
        progress.percent = 25;
        progress.format = 'Estabelecendo conexão segura';
        this.setState(progress);

        let get = undefined;
        const api = new API();
        try {
            // * Token
            const convenioId = this.state.payment.convenio.key;
            const key = this.state.payment.estabelecimento.integracao.key;
            get = await api.getToken(convenioId);

            progress.percent = 50;
            progress.format = 'Autorizando pagamento';
            this.setState(progress);

            // * Autorização de pagamento
            const payment = await api.paymentGenerate(
                get.token,
                key,
                1,
                'Moub/Site',
                this.state.payment.valorEmCentavos
            );

            progress.percent = 75;
            progress.format = 'Enviando pagamento';
            this.setState(progress);

            const result = await api.pay(
                get.token,
                payment.authorization,
                this.state.payment.beneficiario.id,
                this.state.payment.valorEmCentavos,
                this.state.payment.data._d
            );

            progress.percent = 100;
            progress.format = 'Validando pagamento';
            this.setState({ progress, paymentReturn: result.ret });

            setTimeout(() => {
                const step = this.state.step + 1;
                const resultType = 'success';
                const resultText = result.ret.mensagem;
                const resultTextSubTitle = `O saldo do beneficiário agora é de ${this.formatarMoeda(result.ret.saldo / 100)}`;
                this.setState({ step, resultType, resultText: resultText, resultTextSubTitle: resultTextSubTitle });
            }, (2500));

        } catch (err) {
            debugger;
            const step = 3;
            let resultText = err.message ? err.message : err.error;
            if (err.ret) {
                resultText = err.ret.error;
            }
            const resultType = 'error';
            const resultTextSubTitle = 'Tente novamente';
            this.setState({ step, resultText, resultType, resultTextSubTitle, loading: false });
            return;
        }



        // setTimeout(() => {

        // }, timeout);

    }

    formatarMoeda(valor) {
        valor = parseFloat(valor).toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
        });
        return valor;
    }

    render() {
        return (
            <div>
                <PageLayout selectItem={"estorno-debito"}>

                    <Steps current={this.state.step} direction="horizontal">
                        <Step title="Estabelecimento" subTitle="Onde foi o débito?" description={this.state.descriptions.location} />
                        <Step title="Identificação" subTitle="Quem comprou/Quando/Quanto?" description={this.state.descriptions.whoIs} />
                        <Step title="Captura" subTitle="Processamento" />
                        <Step title="Resultado" subTitle="Deu certo?" />
                    </Steps>

                    {this.state.step === 0 && (
                        <Spin indicator={antIcon} spinning={this.state.loading}>
                            <div className="capturaContainer">

                                <label>Selecione:&nbsp;</label>
                                <Select
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.props.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                    disabled={this.state.loading}
                                    optionFilterProp="children"
                                    style={{ width: '400px' }}
                                    placeholder='Selecione um estabelecimento'
                                    onChange={this.onEstabelecimentoChange}
                                    value={this.state.payment.estabelecimento.key}
                                >{this.estabelecimentosOptions}</Select>

                                {this.state.payment.estabelecimento.key && (

                                    <Button
                                        className="capturaBtnProximo"
                                        onClick={this.btnNext}
                                        type="primary">Próximo</Button>

                                )}
                            </div>
                        </Spin>
                    )}


                    {this.state.step === 1 && (

                        <Spin indicator={antIcon} spinning={this.state.loading}>


                            <div className="capturaContainer">
                                <div className="capturaDivSeparadora">
                                    <label>Convênio:&nbsp;</label>
                                    <Select

                                        showSearch
                                        filterOption={(input, option) =>
                                            option.props.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >= 0
                                        }
                                        disabled={this.state.loading}
                                        optionFilterProp="children"
                                        style={{ left: '13px', width: '400px' }}
                                        placeholder='Selecione um convênio'
                                        onChange={this.onConvenioChange}
                                        value={this.state.payment.convenio.key}
                                    >{this.conveniosOptions}</Select>
                                </div>
                                <div className="capturaDivSeparadora">
                                    <label>Beneficiário:&nbsp;</label>
                                    <Select
                                        ref={(el) => this.beneficiarioRef = el}
                                        disabled={this.state.loading}
                                        showSearch
                                        optionFilterProp="children"
                                        style={{ width: '400px' }}
                                        placeholder='Selecione um beneficiário'
                                        onChange={this.onBeneficiarioChange}
                                        value={this.state.payment.beneficiario.id}
                                    >
                                        {this.beneficiariosOptions}
                                    </Select>
                                </div>

                                <div className="capturaDivSeparadora">
                                    <label>Data:&nbsp;</label>
                                    <DatePicker
                                        format={dateFormat}
                                        showTime
                                        style={{ position: 'relative', left: '50px' }}
                                        onChange={this.onDataChange}
                                        disabledDate={this.disabledDate}
                                        disabled={this.state.loading}
                                    ></DatePicker>
                                </div>


                                <div className="capturaDivSeparadora">
                                    <label>Valor:&nbsp;</label>
                                    <CurrencyInput
                                        disabled={this.state.loading}
                                        className="valorCompraCaptura"
                                        prefix="R$ "
                                        decimalSeparator=","
                                        thousandSeparator="."
                                        value={this.state.payment.valor}
                                        onChange={this.onValorChange}
                                        style={{ position: 'relative', left: '32px', width: '160px' }} />

                                </div>


                                <div class="row capturaDivSeparadoraAlinhadaDireita">

                                    <div class="col-lg-3 col-sm-6">
                                        <div class="card-box bg-green" style={{ width: '250px' }}>
                                            <div class="inner">
                                                <h3> {this.state.saldo} </h3>
                                                <p> Saldo </p>
                                            </div>
                                            <div class="icon">
                                                <i class="fa fa-arrow-up " aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <Button
                                    className="capturaBtnProximo"
                                    onClick={this.btnPrior}
                                >Anterior</Button>

                                <Button
                                    className="capturaBtnProximo"
                                    onClick={this.btnNext}
                                    type="primary">Próximo</Button>

                            </div>
                        </Spin>
                    )}

                    {this.state.step === 2 && (
                        <div className="capturaContainer">
                            <div className="capturaDivSeparadoraCenter">
                                <Progress
                                    type="circle"
                                    percent={this.state.progress.percent}
                                    format={() => this.state.progress.format}

                                />
                            </div>

                        </div>
                    )}


                    {this.state.step === 3 && (
                        <div className="capturaContainer">
                            <div className="capturaDivSeparadoraCenter">
                                <Result
                                    status={this.state.resultType}
                                    title={this.state.resultText}
                                    subTitle={this.state.resultTextSubTitle}
                                    extra={[
                                        <Button type="primary" key="console" onClick={() => this.btnStart()} >
                                            Finalizar
                                        </Button>,
                                    ]}
                                />
                            </div>
                        </div>
                    )}

                </PageLayout>
            </div>
        )
    }

}

export default withRouter(Captura);

