import React from "react";
import { withRouter } from "react-router";
import { Table, Button, Icon, Modal, Input, Tooltip } from "antd";

import PageLayout from "../../components/layout/PageLayout";
import AdministradorUsuarioCRUD from "../../components/cruds/administradorUsuarios/AdministradorUsuarioCRUD";
import administradorDB from "../../dataManager/dtmAdministrador";
import { getOwnerId, currentUserCol, getUserType, isAdministrador, isGestor } from "../../components/auth/auth-provider";
import administradorUsuarioDB from "../../dataManager/dtmAdministradorUsuario";
import mensagem from "../../components/messages/message";
import history from "../../history";
import listObj from "../../components/listSearch/listSearch";

import "./AdministradorUsuario.css";
import vertteStorage from "../../components/localstorage/localstorage";

const { Search } = Input;


class AdministradorUsuario extends React.Component {
  state = {
    administradores: [],
    usuarios: [],
    usuariosOriginal: [],
    isSearching: false,
    permissoes: {
      naoCadastrar: false,
      naoEditar: false
    },
    canUpdate: false,
    loading: true
  };

  constructor(props) {
    super(props);
    this.headerElement = React.createRef();
    this.updateList = this.updateList.bind(this);
    this.applyFilter = this.applyFilter.bind(this);
  }

  async componentDidMount() {
    const userType = await getUserType();
    const ownerId = await getOwnerId();

    let naoCadastrar = false;
    let naoEditar = false;

    const userCol = currentUserCol();

    // Se o tipo do usuário for administrador, não preciso verificar se ele tem
    // Permissão ou não para acessar a página. Ele tem!
    if (!await isAdministrador() && userCol) {
      const isOk = userCol.permissoes.some(function (v) { return v.indexOf("usuarios") >= 0 });

      if (!isOk) {
        this.setState({ loading: false });
        await mensagem.avisar('Você não tem permisão para acessar essa página.');
        history.push('/dashboard');
        history.go();
        return;
      }

      naoCadastrar = !userCol.permissoes.some(function (v) { return v.indexOf("usuarios-cadastrar") >= 0 });

      if (naoCadastrar) {
        naoCadastrar = userCol.permissoes.indexOf('usuarios') === -1;
      }
      naoEditar = !userCol.permissoes.some(function (v) { return v.indexOf("usuarios-editar") >= 0 });

      if (naoEditar) {
        naoEditar = userCol.permissoes.indexOf('usuarios') === -1;
      }
    }

    let administradores = undefined;
    let usuarios = undefined;

    // Regra de Acesso para Administradores: 
    // 1 - Pego apenas o administrador que está logado;
    // 2 - Filtro apenas os usuários do administrador logado.

    if (await isAdministrador()) {
      const administrador = await administradorDB.getById(userCol.key);

      if (administrador) {
        administradores = [];
        administradores.push(administrador);
      }

      usuarios = await administradorUsuarioDB.getByOwnerIdAndAdminId(ownerId, userCol.key, true, true);
    } else if (await isGestor()) {
      administradores = await administradorDB.getByOwnerIdCustom(ownerId);
      usuarios = await administradorUsuarioDB.getByOwnerIdCustom(ownerId, true, true);
    } else {
      const administrador = await administradorDB.getById(userCol.administrador.id);

      if (administrador) {
        administradores = [];
        administradores.push(administrador);
      }

      usuarios = await administradorUsuarioDB.getByOwnerIdAndAdminId(ownerId, userCol.administrador.id, true, true);
    }

    this.setState({
      userType,
      usuarios,
      usuariosOriginal: usuarios,
      administradores,
      permissoes: { naoCadastrar, naoEditar },
      loading: false
    });
  }

  async applyFilter(text) {
    const { usuariosOriginal } = this.state;

    if (text === '') {
      this.setState({
        usuarios: usuariosOriginal,
        isSearching: false
      });

      return;
    };

    const keys = [
      'nome',
      'sobrenome',
      'administrador.nome',
      'email',
      'endereco.telefone',
      'ativoStr'
    ];

    const usuarios = listObj.search(usuariosOriginal, text, keys);

    this.setState({
      usuarios,
      isSearching: true
    });
  }


  updateList(record) {
    record['ativo'] = record['ativo'] ? 'Sim' : 'Não';

    let usuarios = this.state.usuarios;
    let registroEncontrado = false;

    this.state.usuarios.forEach((item, index) => {
      if (item.key === record.key) {
        usuarios[index] = record;
        registroEncontrado = true;
      }
    });

    if (!registroEncontrado) {
      usuarios.push(record);
    }

    if (this.state.isSearching) {
      let usuariosOriginal = this.state.usuariosOriginal;
      registroEncontrado = false;

      this.state.usuariosOriginal.forEach((item, index) => {
        if (item.key === record.key) {
          usuariosOriginal[index] = record;
          registroEncontrado = true;
        }
      });

      if (!registroEncontrado) {
        usuariosOriginal.push(record);
      }

      this.setState({ usuarios, usuariosOriginal });
    } else {
      this.setState({ usuarios, usuariosOriginal: usuarios });
    }

    vertteStorage.clear('@firebase-cache/update/administradorUsuarioDB/getByOwnerIdCustom');
    vertteStorage.clear('@firebase-cache/update/administradorUsuarioDB/getByOwnerIdAndAdminId');
  }

  editarUsuarioElement(record) {
    if (this.state.permissoes.naoEditar) return;
    record["administradores"] = this.state.administradores;
    this.headerElement.current.editar(record);
  }

  render() {
    const columns = [
      {
        title: "Nome",
        dataIndex: "nome",
        key: "nome"
      },
      {
        title: "Sobrenome",
        dataIndex: "sobrenome",
        key: "sobrenome"
      },
      {
        title: "Administrador",
        dataIndex: "administrador.nome",
        key: "empresaNome"
      },
      {
        title: "E-mail",
        dataIndex: "email",
        key: "email"
      },
      {
        title: 'Telefone',
        dataIndex: 'endereco.telefone',
        key: 'telefone',
      },
      {
        title: "Ativo?",
        dataIndex: "ativoStr",
        key: "ativoStr"
      },
    ];

    if (!this.state.permissoes.naoEditar) {
      columns.push({
        title: '',
        key: 'action',
        render: (text, record) => (
          <span>
            <Tooltip placement="topLeft" title="Editar Cadastro">
              <Icon
                type="edit"
                onClick={() => this.editarUsuarioElement(record)}
              />
            </Tooltip>
          </span>
        ),
      },
      );
    }


    return (
      <div>
        <PageLayout selectItem={"administradores-usuarios"}>
          <Table
            pagination={{
              defaultPageSize: 100,
              position: 'both',
              pageSizeOptions: ["50", "100", "150", "200", "250", "300"],
              showSizeChanger: true,
              locale: { items_per_page: "" }
            }}


            title={() => (
              <Header
                ref={this.headerElement}
                naoCadastrar={this.state.permissoes.naoCadastrar}
                administradores={this.state.administradores}
                applyFilter={this.applyFilter}
                updateList={this.updateList}
              />
            )}
            columns={columns}
            dataSource={this.state.usuarios}
            bordered
            loading={this.state.loading}
          />
        </PageLayout>
      </div>
    );
  }
}

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.novoUsuarioElement = React.createRef();
    this.filterTable = this.filterTable.bind(this);

    this.state = {
      visible: false
    };

    this.novo = this.novo.bind(this);
  }

  novo() {
    const record = [];
    record["administradores"] = this.props.administradores;
    this.novoUsuarioElement.current.show(false, record);
  }

  editar(record) {
    this.novoUsuarioElement.current.show(true, record);
  }

  filterTable(value) {
    const text = typeof value === "object" ? value.target.value : value;
    this.props.applyFilter(text);
  }


  render() {
    return (
      <div>
        <NovoUsuario
          ref={this.novoUsuarioElement}
          updateList={this.props.updateList}
          record={[]}
        ></NovoUsuario>
        <Button disabled={this.props.naoCadastrar} type="primary" onClick={this.novo}>
          <Icon className="icon" type="plus" /> Novo Usuário
        </Button>

        <Search
          placeholder="Procurar"
          onSearch={this.filterTable}
          onChange={this.filterTable}
          style={{ marginLeft: "10px", width: 200 }}
        />

      </div>
    );
  }
}

class NovoUsuario extends React.Component {
  state = {
    visible: false,
    confirmLoading: false,
    editMode: false,
    record: [],
    empresas: []
  };

  constructor(props) {
    super(props);
    this.handleOk = this.handleOk.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  show(editMode, record) {
    if (!record) {
      record = [];
    }

    this.setState({
      visible: true,
      confirmLoading: false,
      editMode: editMode,
      record: record
    });
  }

  handleOk(record) {
    this.setState({ visible: false });
    this.props.updateList(record);
  }

  handleCancel() {
    this.setState({ visible: false });
  }

  render() {
    return (
      <Modal
        title="Usuário"
        visible={this.state.visible}
        destroyOnClose={true}
        confirmLoading={this.state.confirmLoading}
        centered={true}
        onOk={this.handleOk}
        footer={null}
        closable={true}
        onCancel={() => { this.setState({ visible: false }); }}
      >
        <AdministradorUsuarioCRUD
          handleOk={this.handleOk}
          handleCancel={this.handleCancel}
          editMode={this.state.editMode}
          record={this.state.record}
          empresas={this.state.empresas}
        ></AdministradorUsuarioCRUD>
      </Modal>
    );
  }
}

export default withRouter(AdministradorUsuario);
