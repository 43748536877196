import React from 'react';
import {
  Form,
  Input,
  Tooltip,
  Button,
  Divider,
  Select,
  TreeSelect,
  Icon,
  Tabs,
  message,
} from 'antd';
import { fireBase } from '../../../firebase/firebase';
import { currentUser, getOwnerId } from '../../auth/auth-provider';
import { isCPF, formatCPF, clearCpfFormat } from '../../funcs/utils';
import mensagem from '../../messages/message';
import gestorDB from '../../../dataManager/dtmGestor';
import usuarioDB from '../../../dataManager/dtmUsuario';
import userAdmin from '../../userAdmin/userAdmin';
import auditoria from '../../auditoria/auditoria';


const { Option } = Select;
const { SHOW_PARENT } = TreeSelect;
const { TabPane } = Tabs;


class GestorCRUD extends React.Component {
  state = {
    loading: false,
    confirmDirty: false,
    autoCompleteResult: [],
    convenios: [],
    selecteds: [],
    showConvenio: 'block',
    showAtivo: 'none',
    requireConvenios: true,
    nomeCadastro: 'administrador',
    data: {
      key: this.props.record.key,
      ativo: 'Sim',
      nome: '',
      sobrenome: '',
      cpf: '',
      email: '',
      endereco: {
        logradouro: '',
        bairro: '',
        cidade: '',
        uf: '',
        telefone: '',
      }
    },
    permissoes: [],
    treeData: [],
  };


  treeGestores = [
    {
      title: 'Gestores',
      value: 'gestores',
      key: 'gestores',
      children: [
        {
          title: 'Cadastrar',
          value: 'gestores-cadastrar',
          key: 'gestores-cadastrar',
        },
        {
          title: 'Editar',
          value: 'gestores-editar',
          key: 'gestores-editar',
        },
      ],
    },
    {
      title: 'Administradores',
      value: 'administradores',
      key: 'administradores',
      children: [
        {
          title: 'Cadastrar',
          value: 'administradores-cadastrar',
          key: 'administradores-cadastrar',
        },
        {
          title: 'Editar',
          value: 'administradores-editar',
          key: 'administradores-editar',
        },
      ],
    },
    {
      title: 'Usuários',
      value: 'usuarios',
      key: 'usuarios',
      children: [
        {
          title: 'Cadastrar',
          value: 'usuarios-cadastrar',
          key: 'usuarios-cadastrar',
        },
        {
          title: 'Editar',
          value: 'usuarios-editar',
          key: 'usuarios-editar',
        },
      ],
    },
    {
      title: 'Convênios',
      value: 'convenios',
      key: 'convenios',
      children: [
        {
          title: 'Cadastrar',
          value: 'convenios-cadastrar',
          key: 'convenios-cadastrar',
        },
        {
          title: 'Editar',
          value: 'convenios-editar',
          key: 'convenios-editar',
        },
      ],
    },
    {
      title: 'Beneficiários',
      value: 'beneficiarios',
      key: 'beneficiarios',
      children: [
        {
          title: 'Cadastrar',
          value: 'beneficiarios-cadastrar',
          key: 'beneficiarios-cadastrar',
        },
        {
          title: 'Editar',
          value: 'beneficiarios-editar',
          key: 'beneficiarios-editar',
        },
      ],
    },
    {
      title: 'Pedidos',
      value: 'pedidos',
      key: 'pedidos',
      children: [
        {
          title: 'Cadastrar',
          value: 'pedidos-cadastrar',
          key: 'pedidos-cadastrar',
        },
        {
          title: 'Cancelar',
          value: 'pedidos-cancelar',
          key: 'pedidos-cancelar',
        },
      ],
    },
    {
      title: 'Estabelecimentos',
      value: 'estabelecimentos',
      key: 'estabelecimentos',
      children: [
        {
          title: 'Cadastrar',
          value: 'estabelecimentos-cadastrar',
          key: 'estabelecimentos-cadastrar',
        },
        {
          title: 'Editar',
          value: 'estabelecimentos-editar',
          key: 'estabelecimentos-editar',
        },
      ],
    },
    {
      title: 'Estornos',
      value: 'estornoDebito',
      key: 'estornoDebito',
    },
    {
      title: 'Capturas',
      value: 'capturaDebito',
      key: 'capturaDebito',
    },
    {
      title: 'Relatórios',
      value: 'relatorios',
      key: 'relatorios',
      children: [
        {
          title: 'Clientes (Convênios)',
          value: 'relatorios-convenios',
          key: 'relatorios-convenios',
        },
        {
          title: 'Movimentação',
          value: 'relatorios-movimentacao',
          key: 'relatorios-movimentacao',
        },
        {
          title: 'Histórico de Movimentação',
          value: 'relatorios-historico-movimentacao',
          key: 'relatorios-historico-movimentacao',
        },
        {
          title: 'Saldos',
          value: 'relatorios-saldos',
          key: 'relatorios-saldos',
        },
        {
          title: 'Extrato',
          value: 'relatorios-extrato',
          key: 'relatorios-extrato',
        },
        {
          title: 'Desconto em Folha',
          value: 'relatorios-descfolha',
          key: 'relatorios-descfolha',
        },
        {
          title: 'Saldo Convênios',
          value: 'relatorios-saldoConvenios',
          key: 'relatorios-saldoConvenios',
        },
        {
          title: 'Vencimentos de Cartões',
          value: 'relatorios-vencimento-cartao',
          key: 'relatorios-vencimento-cartao',
        },

      ],
    },

    {
      title: 'Ferramentas',
      value: 'tools',
      key: 'tools',
      children: [
        {
          title: 'Impressão de Cartões',
          value: 'cards-to-print',
          key: 'cards-to-print',
        },
      ]
    },
    {
      title: 'Configurações',
      value: 'configuracoes',
      key: 'configuracoes',
      children: [
        {
          title: 'Ajustar',
          value: 'configuracoes-ajustar',
          key: 'configuracoes-ajustar',
        },
      ],
    },
  ]

  treeConveniados = [
    {
      title: 'Usuários',
      value: 'convenio-usuarios',
      key: 'convenio-usuarios',
      children: [
        {
          title: 'Cadastrar',
          value: 'convenio-usuarios-cadastrar',
          key: 'convenio-usuarios-cadastrar',
        },
        {
          title: 'Editar',
          value: 'convenio-usuarios-editar',
          key: 'convenio-usuarios-editar',
        },
      ],
    },
    {
      title: 'Beneficiários',
      value: 'convenio-beneficiarios',
      key: 'convenio-beneficiarios',
      children: [
        {
          title: 'Cadastrar',
          value: 'convenio-beneficiarios-cadastrar',
          key: 'convenio-beneficiarios-cadastrar',
        },
        {
          title: 'Editar',
          value: 'convenio-beneficiarios-editar',
          key: 'convenio-beneficiarios-editar',
        },
      ],
    },
    {
      title: 'Pedidos',
      value: 'convenio-pedidos',
      key: 'convenio-pedidos',
      children: [
        {
          title: 'Cadastrar',
          value: 'convenio-pedidos-cadastrar',
          key: 'convenio-pedidos-cadastrar',
        },
        {
          title: 'Cancelar',
          value: 'convenio-pedidos-cancelar',
          key: 'convenio-pedidos-cancelar',
        },
      ],
    },
    {
      title: 'Créditos',
      value: 'convenio-creditos',
      key: 'convenio-creditos',
      children: [
        {
          title: 'Cadastrar',
          value: 'convenio-creditos-cadastrar',
          key: 'convenio-creditos-cadastrar',
        },
        {
          title: 'Cancelar',
          value: 'convenio-creditos-editar',
          key: 'convenio-creditos-editar',
        },
      ],
    }
  ];

  constructor(props) {
    super(props);
    this.cancelClick = this.cancelClick.bind(this);
    this.selectHandleChange = this.selectHandleChange.bind(this);
    this.onPermissoesChange = this.onPermissoesChange.bind(this);
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (err) {
        return;
      }

      this.setState({ loading: true });

      const gravarConvenios = [];
      this.state.selecteds.forEach(obj => {
        const convenio = this.state.convenios.filter((item) => {
          return item.key === obj
        })[0];
        gravarConvenios.push({
          id: convenio.key,
          nome: convenio.props.children,
          cnpj: this.props.convenioUnico ? this.props.convenioUnico.cnpj : '',
          email: this.props.convenioUnico ? this.props.convenioUnico.email : '',
          prazo: this.props.convenioUnico ? this.props.convenioUnico.prazo : '',
          telefone: this.props.convenioUnico ? this.props.convenioUnico.endereco.telefone : '',
        });
      });

      if (!isCPF(values.cpf)) {
        mensagem.openNotificationWithIcon('error', 'CPF inválido', 'O CPF informado não está correto.');
        this.setState({ loading: false });
        return;
      }
      values.cpf = formatCPF(values.cpf);

      // procuro pelo CPF para checar se está repetido
      let snapshot = await gestorDB.getByCPF(values.cpf);

      // Verifico se está havendo uma duplicação de CPF
      if (snapshot !== undefined) {
        if ((this.props.editMode && snapshot[0].key !== this.props.record.key) ||
          (this.props.editMode && snapshot.length > 1) || (!this.props.editMode)) {
          mensagem.openNotificationWithIcon('error', 'Duplicação', 'O CPF informado já foi cadastrado.');
          this.setState({ loading: false });
          return;
        }
      }

      // procuro pelo email para checar se está repetido
      snapshot = await gestorDB.getByEmail(values.email);
      if (snapshot !== undefined) {
        if ((this.props.editMode && snapshot[0].key !== this.props.record.key) ||
          (this.props.editMode && snapshot.length > 1) || (!this.props.editMode)) {
          mensagem.openNotificationWithIcon('error', 'Duplicação', 'O email informado já foi cadastrado.');
          this.setState({ loading: false });
          return;
        }
      }

      const ownerId = await getOwnerId();

      const item = {
        ativo: values.ativo.toLocaleLowerCase() === 'sim' ? true : false,
        nome: values.nome,
        sobrenome: values.sobrenome,
        cpf: values.cpf,
        email: values.email,
        endereco: {
          logradouro: values.endereco,
          bairro: values.bairro,
          cidade: values.cidade,
          uf: values.uf,
          telefone: values.telefone
        },
        permissoes: this.state.permissoes,
        ownerId,
      }

      let novoId = '';
      if (!this.props.editMode) {

        // Antes de mais nada, crio um novo login com o email
        // e os seis dígitos do cpf como senha
        const cpf = clearCpfFormat(values.cpf);
        const obj = await fireBase.auth().createUserWithEmailAndPassword(values.email, cpf.substr(0, 6));
        if (!obj) {
          message.error(`Erro ao criar o gestor com o email ${values.email}`);
          this.setState({ loading: false });
          return;
        }

        const rec = await gestorDB.add(item);
        novoId = rec.id;

        const usuario = {
          uid: obj.user.uid,
          tipo: 'Gestor',
          ownerId,
        }
        // Salvo o usuário como gestor
        await usuarioDB.add(usuario);
      }
      else {
        const isChanged = this.props.record.email !== values.email;
        const podeSalvar = !isChanged || await mensagem.confirmar('Alteração de email detectada. Confirma?');

        if (!podeSalvar) {
          this.setState({ loading: false });
          message.warning('Verifique o email do gestor');
          return;
        }

        if (isChanged) {
          message.info('Aguarde, estamos alterando o email...');
          await userAdmin.changeUserMail(this.props.record.email, values.email);
        }


        let ativo = this.props.record.ativo;
        if (typeof ativo === 'string') {
          ativo = ativo.toLocaleLowerCase() === 'sim' ? true : false;
        }

        const before = {
          ativo,
          nome: this.props.record.nome,
          sobrenome: this.props.record.sobrenome,
          cpf: this.props.record.cpf,
          email: this.props.record.email,
          endereco: this.props.record.endereco,
          permissoes: this.props.record.permissoes,
          ownerId: item.ownerId,
        }

        // const dadosIguais = auditoria.isEqual(item, before);

        // if (dadosIguais) {
        //   mensagem.openNotificationWithIcon('info', 'Aviso', 'Nada foi alterado', 3);
        //   item['key'] = novoId ? novoId : this.props.record.key;
        //   item['ativoStr'] = values.ativo.toLocaleLowerCase() === 'sim' ? 'Sim' : 'Não';
        //   this.props.handleOk();
        //   this.setState({ loading: false });
        //   return;
        // }

        await gestorDB.update(this.props.record.key, item);

        const { uid, displayName, email } = currentUser();
        const userName = displayName ? displayName : email;

        auditoria.post(
          item.ownerId,
          uid,
          userName,
          this.props.record.key,
          'gestores',
          `O usuário ${userName} alterou o registro ${this.props.record.key} em 'gestores'`,
          'Alteração no registro de gestor',
          before,
          item
        );

        const active = values.ativo.toLocaleLowerCase() === 'sim' ? true : false;

        const statusGestor = values.ativo.toLocaleLowerCase() === 'sim' ? true : false;
        const mudouAtivo = this.props.record.ativo !== statusGestor;
        if (mudouAtivo) {
          userAdmin.activeOrDeactiveUser(item.email, active);
        }
      }

      const tipoAtualizacao = this.props.editMode ? 'atualizado' : 'cadastrado';
      mensagem.openNotificationWithIcon('success', 'Perfeito!', `O ${this.state.nomeCadastro} ${values.nome} foi ${tipoAtualizacao} com sucesso`, 3);
      item['key'] = novoId ? novoId : this.props.record.key;
      item['ativoStr'] = values.ativo.toLocaleLowerCase() === 'sim' ? 'Sim' : 'Não';
      this.props.handleOk(item);
      this.setState({ loading: false });
    });
  };

  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  componentDidMount() {
    const selecteds = [];
    const { Option } = Select;
    const convenios = [];

    if (!this.props.convenioUnico) {
      this.props.convenios.forEach(item => {
        convenios.push(<Option key={item.key}>{item.nomeFantasia}</Option>);
      });
      this.setState({ treeData: this.treeGestores, showConvenio: 'none', requireConvenios: false });
    }
    else {
      selecteds.push(this.props.convenioUnico.key);
      this.setState({ treeData: this.treeConveniados, showConvenio: 'none', requireConvenios: false, selecteds: selecteds, nomeCadastro: 'usuário conveniado' });
      convenios.push(<Option key={this.props.convenioUnico.key}>{this.props.convenioUnico.nomeFantasia}</Option>);
    }

    this.setState({ convenios });
    if (this.props.editMode && this.props.record) {
      if (selecteds.length === 0) {
        this.props.record.convenios.forEach(item => {
          selecteds.push(item.id);
        });
      }

      const data = {
        key: this.props.record.key,
        ativo: this.props.record.ativo ? 'sim' : 'nao',
        nome: this.props.record.nome,
        sobrenome: this.props.record.sobrenome,
        cpf: this.props.record.cpf,
        email: this.props.record.email,
        endereco: this.props.record.endereco,
      };

      this.setState({
        data,
        showAtivo: 'block',
        selecteds,
        permissoes: this.props.record.permissoes,
      });
    }
  }

  cancelClick() {
    this.props.handleCancel();
  }

  selectHandleChange(value) {
    this.setState({ selecteds: value });
  }

  onPermissoesChange(value) {
    this.setState({ permissoes: value });
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };

    return (
      <Form {...formItemLayout} onSubmit={this.handleSubmit}>

        <Tabs defaultActiveKey="geral">

          <TabPane tab="Geral" key="geral">

            <Form.Item
              style={{ display: this.state.showConvenio }}
              ref='selectConvenio'
              label={
                <span>
                  Convênio&nbsp;
                  <Tooltip title={`Escolha os convênios que o ${this.state.nomeCadastro} atende`}>
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </span>
              }
            >
              {getFieldDecorator('convenios', {
                initialValue: this.state.selecteds,
                rules: [{ required: this.state.requireConvenios, message: 'selecione pelo menos um Convênio', type: 'array' }],
              })(
                <Select
                  mode="multiple"
                  style={{ width: '100%' }}
                  placeholder="Selecione pelo menos um convênio"
                  onChange={this.selectHandleChange}
                >
                  {this.state.convenios}
                </Select>,
              )}
            </Form.Item>

            <Form.Item
              label={
                <span>
                  Permissões&nbsp;
                  <Tooltip title={`Que recursos esse ${this.state.nomeCadastro} pode acessar?`}>
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </span>
              }
            >
              {getFieldDecorator('permissoes', {
                initialValue: this.state.permissoes,
                rules: [{ required: true, message: `Informe que recursos o ${this.state.nomeCadastro} pode acessar` }],
              })(

                <TreeSelect
                  style={{ width: '100%' }}
                  treeCheckable={true}
                  showCheckedStrategy={SHOW_PARENT}
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  treeData={this.state.treeData}
                  placeholder="Selecione uma ou mais permissões"
                  treeDefaultExpandAll
                  onChange={this.onPermissoesChange} />
              )}
            </Form.Item>

            <Form.Item
              style={{ display: this.state.showAtivo }}
              label={
                <span>
                  Ativo&nbsp;
                  <Tooltip title={`Esse ${this.state.nomeCadastro} está ativo?`}>
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </span>
              }
            >
              {getFieldDecorator('ativo', {
                initialValue: this.state.data.ativo,
                rules: [{ required: true, message: `Informe se o ${this.state.nomeCadastro} está ativo` }],
              })(

                <Select style={{ width: 120 }}>
                  <Option value="sim">Sim</Option>
                  <Option value="nao">Não</Option>
                </Select>

              )}
            </Form.Item>

            <Form.Item
              ref='txtNome'
              label={
                <span>
                  Nome&nbsp;
                  <Tooltip title="Qual é o nome?">
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </span>
              }
            >
              {getFieldDecorator('nome', {
                initialValue: this.state.data.nome,
                rules: [{ required: true, message: 'Informe o nome', whitespace: true }],
              })(<Input />)}
            </Form.Item>

            <Form.Item
              label={
                <span>
                  Sobrenome&nbsp;
                  <Tooltip title="Qual é o sobrenome?">
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </span>
              }
            >
              {getFieldDecorator('sobrenome', {
                initialValue: this.state.data.sobrenome,
                rules: [{ required: true, message: 'Informe o sobrenome', whitespace: true }],
              })(<Input />)}
            </Form.Item>

            <Form.Item
              label={
                <span>
                  CPF&nbsp;
                  <Tooltip title="Qual é o CPF?">
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </span>
              }
            >
              {getFieldDecorator('cpf', {
                initialValue: this.state.data.cpf,
                rules: [{ required: true, message: 'Informe o CPF', whitespace: false }],
              })(<Input />)}
            </Form.Item>


            <Form.Item label="E-mail" ref="txtEmail">
              {getFieldDecorator('email', {
                initialValue: this.state.data.email,
                rules: [
                  {
                    type: 'email',
                    message: 'Email não é válido',
                  },
                  {
                    required: true,
                    message: 'Informe o email',
                  },
                ],
              })(<Input />)}
            </Form.Item>

          </TabPane>

          <TabPane tab="Endereço" key="endereco">

            <Form.Item label="Telefone">
              {getFieldDecorator('telefone', {
                initialValue: this.state.data.endereco.telefone,
                rules: [{ required: false, message: 'Informe o telefone' }],
              })(<Input style={{ width: '100%' }} />)}
            </Form.Item>

            <Form.Item label="Endereço">
              {getFieldDecorator('endereco', {
                initialValue: this.state.data.endereco.logradouro,
                rules: [{ required: false, message: 'Informe o endereço' }],
              })(<Input style={{ width: '100%' }} />)}
            </Form.Item>

            <Form.Item label="Bairro">
              {getFieldDecorator('bairro', {
                initialValue: this.state.data.endereco.bairro,
                rules: [{ required: false, message: 'Informe o bairro' }],
              })(<Input style={{ width: '50%' }} />)}
            </Form.Item>

            <Form.Item label="Cidade">
              {getFieldDecorator('cidade', {
                initialValue: this.state.data.endereco.cidade,
                rules: [{ required: false, message: 'Informe a cidade' }],
              })(<Input style={{ width: '70%' }} />)}
            </Form.Item>

            <Form.Item label="UF">
              {getFieldDecorator('uf', {
                initialValue: this.state.data.endereco.uf,
                rules: [{ required: false, message: 'Informe a UF' }],
              })(<Input style={{ width: '20%' }} />)}
            </Form.Item>

          </TabPane>
        </Tabs>

        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit" loading={this.state.loading}>
            Salvar
          </Button>
          <Divider type="vertical" />
          <Button onClick={this.cancelClick}>
            Cancelar
          </Button>

        </Form.Item>
      </Form>
    );
  }
}

export default Form.create()(GestorCRUD);