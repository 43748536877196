import { Component } from 'react';
import { getUserType } from "../auth/auth-provider";

class Funcs extends Component {

    async podeAbrirRelatorio(userCol, relatorio) {
        const userType = await getUserType();
        if (userType === 'Gestor' || userType === 'AdministradorUsuario') {
            // Configuração dos relatórios:
            let relatorios = userCol.permissoes.indexOf('relatorios') > -1;
            let relatorioSolicitado = userCol.permissoes.indexOf(relatorio) > -1;
            // Verifico se tem acesso a todos os relatórios ou
            // ao relatório específico
            return (relatorios || relatorioSolicitado);
        }
        else {
            return false;
        }
    }

    async podeAbrirPagina(userCol, relatorio, prefixo) {
        const userType = await getUserType();
        if (userType === 'Gestor' || userType === 'AdministradorUsuario') {
            // Configuração:
            let paginas = userCol.permissoes.indexOf(!prefixo ? 'relatorios' : prefixo) > -1;
            let paginaSolicitada = userCol.permissoes.indexOf(relatorio) > -1;
            // Verifico se tem acesso a todos os relatórios ou
            // ao relatório específico
            return (paginas || paginaSolicitada);
        }
        else {
            return false;
        }
    }
}

const funcs = new Funcs();
export default funcs