import React from 'react';
import { withRouter } from 'react-router'
import { Table, Button, Icon, Modal, Input, Tooltip } from 'antd';
import { getOwnerId, getUserType, currentUserCol } from '../../components/auth/auth-provider';

import EstabelecimentoCRUD from '../../components/cruds/estabelecimentos/EstabelecimentoCRUD';
import PageLayout from '../../components/layout/PageLayout';
import history from '../../history';
import mensagem from '../../components/messages/message';
import estabelecimentoDB from '../../dataManager/dtmEstabelecimento';
import listObj from '../../components/listSearch/listSearch';
import vertteStorage from '../../components/localstorage/localstorage';

import './Estabelecimento.css';


const { Search } = Input;

class Estabelecimento extends React.Component {
    state = {
        estabelecimentos: [],
        estabelecimentosOriginal: [],
        isSearching: false,
        loading: true,
        userType: 'Gerente',
        permissoes: {
            naoCadastrar: false,
            naoEditar: false
        }
    }

    constructor(props) {
        super(props);

        this.headerEstabelecimentoElement = React.createRef();

        this.updateList = this.updateList.bind(this);
        this.applyFilter = this.applyFilter.bind(this);
    }

    async componentDidMount() {
        this.getEstabelecimentos();
    }

    async getEstabelecimentos() {
        const gestorId = await getOwnerId();
        const userType = await getUserType();
        const userCol = currentUserCol();

        let naoCadastrar = false;
        let naoEditar = false;

        if (userCol || userType !== 'Gestor') {
            const isOk = userType === 'Gestor' && userCol.permissoes.some(function (v) { return v.indexOf("estabelecimentos") >= 0 });

            if (!isOk) {
                this.setState({ loading: false });
                await mensagem.avisar('Você não tem permisão para acessar essa página.');
                history.push('/dashboard');
                history.go();
                return;
            }

            naoCadastrar = !userCol.permissoes.some(function (v) { return v.indexOf("estabelecimentos-cadastrar") >= 0 });
            if (naoCadastrar) {
                naoCadastrar = userCol.permissoes.indexOf('estabelecimentos') === -1;
            }
            naoEditar = !userCol.permissoes.some(function (v) { return v.indexOf("estabelecimentos-editar") >= 0 });
            if (naoEditar) {
                naoEditar = userCol.permissoes.indexOf('estabelecimentos') === -1;
            }
        }

        const estabelecimentos = await estabelecimentoDB.getByOwnerIdCustom(gestorId, true, true);

        this.setState({
            userType,
            estabelecimentos,
            estabelecimentosOriginal: estabelecimentos,
            permissoes: { naoCadastrar, naoEditar },
            loading: false
        });
    }

    async applyFilter(text) {
        const { estabelecimentosOriginal } = this.state;

        if (text === '') {
            this.setState({
                estabelecimentos: estabelecimentosOriginal,
                isSearching: false
            });

            return;
        };

        const keys = [
            'cnpj',
            'razaoSocial',
            'nomeFantasia',
            'email',
            'endereco.telefone',
        ];

        const estabelecimentos = listObj.search(estabelecimentosOriginal, text, keys);

        this.setState({
            estabelecimentos,
            isSearching: true
        });
    }



    updateList(record) {
        let estabelecimentos = this.state.estabelecimentos;
        let registroEncontrado = false;

        this.state.estabelecimentos.forEach((item, index) => {
            if (item.key === record.key) {
                estabelecimentos[index] = record;
                registroEncontrado = true;
            }
        });

        if (!registroEncontrado) {
            estabelecimentos.push(record);
        }

        if (this.state.isSearching) {
            let estabelecimentosOriginal = this.state.estabelecimentosOriginal;
            registroEncontrado = false;

            this.state.estabelecimentosOriginal.forEach((item, index) => {
                if (item.key === record.key) {
                    estabelecimentosOriginal[index] = record;
                    registroEncontrado = true;
                }
            });

            if (!registroEncontrado) {
                estabelecimentosOriginal.push(record);
            }

            this.setState({ estabelecimentos, estabelecimentosOriginal });
        } else {
            this.setState({ estabelecimentos, estabelecimentosOriginal: estabelecimentos });
        }

        vertteStorage.clear('/estabelecimentoDB/getByOwnerIdCustom');
    }

    handleCancel = () => {
        this.setState({ visible: false });
    };

    editarEstabelecimentoElement(record) {
        if (this.state.permissoes.naoEditar) return;
        this.headerEstabelecimentoElement.current.editarEstabelecimento(record);
    }

    render() {

        const columns = [
            {
                title: 'CNPJ',
                dataIndex: 'cnpj',
                key: 'cnpj',
            },
            {
                title: 'Razão Social',
                dataIndex: 'razaoSocial',
                key: 'razaoSocial',
                editable: false,
            },
            {
                title: 'Nome Fantasia',
                dataIndex: 'nomeFantasia',
                key: 'nomeFantasia',
            },
            {
                title: 'E-mail',
                dataIndex: 'email',
                key: 'email',
            },
            {
                title: 'Telefone',
                dataIndex: 'endereco.telefone',
                key: 'telefone',
            }
        ];

        if (!this.state.permissoes.naoEditar) {
            columns.push({
                title: '',
                key: 'action',
                render: (text, record) => (
                    <span>
                        <Tooltip placement="topLeft" title="Editar Cadastro">
                            <Icon
                                type="edit"
                                onClick={() => this.editarEstabelecimentoElement(record)}
                            />
                        </Tooltip>
                    </span>
                ),
            });
        }


        return (
            <div className="divTable">
                <PageLayout selectItem={'estabelecimentos'}>
                    <Table
                        pagination={{ pageSize: 10, position: 'top' }}
                        title={() =>
                            <HeaderEstabelecimento
                                naoCadastrar={this.state.permissoes.naoCadastrar}
                                ref={this.headerEstabelecimentoElement}
                                applyFilter={this.applyFilter}
                                updateList={this.updateList}
                                userType={this.state.userType} />
                        }
                        columns={columns}
                        dataSource={this.state.estabelecimentos}
                        loading={this.state.loading}
                        bordered
                    />
                </PageLayout>
            </div>
        );
    }
}

class HeaderEstabelecimento extends React.Component {

    state = {
        visible: false
    };

    constructor(props) {
        super(props);
        this.novoEstabelecimentoElement = React.createRef();
        this.novoEstabelecimento = this.novoEstabelecimento.bind(this);
        this.filterTable = this.filterTable.bind(this);
    }

    novoEstabelecimento() {
        this.novoEstabelecimentoElement.current.show(false);
    }

    editarEstabelecimento(record) {
        this.novoEstabelecimentoElement.current.show(true, record);
    }

    filterTable(value) {
        const text = typeof value === "object" ? value.target.value : value;
        this.props.applyFilter(text);
    }

    render() {
        return (
            <div>
                <NovoEstabelecimento ref={this.novoEstabelecimentoElement} updateList={this.props.updateList}></NovoEstabelecimento>
                <Button disabled={this.props.naoCadastrar} type="primary" onClick={this.novoEstabelecimento}><Icon className="icon" type="plus" /> Novo Estabelecimento</Button>

                <Search
                    placeholder="Procurar"
                    onSearch={this.filterTable}
                    onChange={this.filterTable}
                    style={{ marginLeft: "10px", width: 200 }}
                />

            </div>
        );
    }
}

class NovoEstabelecimento extends React.Component {
    state = {
        visible: false,
        confirmLoading: false,
        editMode: false,
        record: []
    }

    constructor(props) {
        super(props);
        this.handleOk = this.handleOk.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    show(editMode, record) {
        if (!record) {
            record = [];
        }

        this.setState({
            visible: true,
            confirmLoading: false,
            editMode: editMode,
            record: record
        });
    }

    handleOk(record) {
        this.setState({ visible: false });
        this.props.updateList(record);
    }

    handleCancel() {
        this.setState({ visible: false });
    }

    render() {
        return (
            <Modal
                title="Estabelecimento"
                visible={this.state.visible}
                destroyOnClose={true}
                confirmLoading={this.state.confirmLoading}
                centered={true}
                onOk={this.handleOk}
                footer={null}
                closable={true}
                onCancel={() => { this.setState({ visible: false }); }}
            >
                <EstabelecimentoCRUD handleOk={this.handleOk} handleCancel={this.handleCancel} editMode={this.state.editMode} record={this.state.record}></EstabelecimentoCRUD>

            </Modal>
        );
    }
}

export default withRouter(Estabelecimento);