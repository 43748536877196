import React from "react";
import { withRouter } from 'react-router';
import './Privacidade.css';


class Privacidade extends React.Component {

    state = {
        numPages: null,
        pageNumber: 1,
    }

    constructor(props) {
        super(props);
        document.title = `Privacidade`;
        this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    }

    render() {
        return (
            <div className="fontePoliticaPrivacidade">
                <p className="titulo">POLÍTICA DE PRIVACIDADE DE BENEFICIÁRIOS</p>
                <br></br>
                {/* Informações gerais */}
                <p className="subTitulo">1. Informações gerais</p>
                <p>A presente Política de Privacidade contém informações a respeito do modo como tratamos, total ou parcialmente, de forma automatizada ou não, os dados pessoais dos usuários que acessam nosso aplicativo. Seu objetivo é esclarecer os interessados acerca dos tipos de dados que são coletados, dos motivos da coleta e da forma como o usuário poderá atualizar, gerenciar ou excluir estas informações.</p>
                <p>Esta Política de Privacidade foi elaborada em conformidade com a Lei Federal n. 12.965 de 23 de abril de 2014 (Marco Civil da Internet), com a Lei Federal n. 13.709, de 14 de agosto de 2018 (Lei de Proteção de Dados Pessoais) e com o Regulamento UE n. 2016/679 de 27 de abril de 2016 (Regulamento Geral Europeu de Proteção de Dados Pessoais - RGDP).</p>
                <p>Esta Política de Privacidade poderá ser atualizada em decorrência de eventual atualização normativa, razão pela qual se convida o usuário a consultar periodicamente esta seção.</p>
                <br></br>
                {/* Direitos do usuário */}
                <p className="subTitulo">2. Direitos do usuário</p>
                <p>O aplicativo se compromete a cumprir as normas previstas no RGPD, em respeito aos seguintes princípios:</p>
                <p className="recuoEsquerdo">- Os dados pessoais do usuário serão processados de forma lícita, leal e transparente (licitude, lealdade e transparência);</p>
                <p className="recuoEsquerdo">- Os dados pessoais do usuário serão coletados apenas para finalidades determinadas, explícitas e legítimas, não podendo ser tratados posteriormente de uma forma incompatível com essas finalidades (limitação das finalidades);</p>
                <p className="recuoEsquerdo">- Os dados pessoais do usuário serão coletados de forma adequada, pertinente e limitada às necessidades do objetivo para os quais eles são processados (minimização dos dados);</p>
                <p className="recuoEsquerdo">- Os dados pessoais do usuário serão exatos e atualizados sempre que necessário, de maneira que os dados inexatos sejam apagados ou retificados quando possível (exatidão);</p>
                <p className="recuoEsquerdo">- Os dados pessoais do usuário serão conservados de uma forma que permita a identificação dos titulares dos dados apenas durante o período necessário para as finalidades para as quais são tratados (limitação da conservação);</p>
                <p className="recuoEsquerdo">- Os dados pessoais do usuário serão tratados de forma segura, protegidos do tratamento não autorizado ou ilícito e contra a sua perda, destruição ou danificação acidental, adotando as medidas técnicas ou organizativas adequadas (integridade e confidencialidade).</p>
                <p>O usuário do aplicativo possui os seguintes direitos, conferidos pela Lei de Proteção de Dados Pessoais e pelo RGPD:</p>
                <p className="recuoEsquerdo">- Direito de confirmação e acesso: é o direito do usuário de obter do aplicativo a confirmação de que os dados pessoais que lhe digam respeito são ou não objeto de tratamento e, se for esse o caso, o direito de acessar os seus dados pessoais;</p>
                <p className="recuoEsquerdo">- Direito de retificação: é o direito do usuário de obter do aplicativo, sem demora injustificada, a retificação dos dados pessoais inexatos que lhe digam respeito;</p>
                <p className="recuoEsquerdo">- Direito à eliminação dos dados (direito ao esquecimento): é o direito do usuário de ter seus dados apagados do aplicativo;</p>
                <p className="recuoEsquerdo">- Direito à limitação do tratamento dos dados: é o direito do usuário de limitar o tratamento de seus dados pessoais, podendo obtê-la quando contesta a exatidão dos dados, quando o tratamento for ilícito, quando o aplicativo não precisar mais dos dados para as finalidades propostas e quando tiver se oposto ao tratamento dos dados e em caso de tratamento de dados desnecessários;</p>
                <p className="recuoEsquerdo">- Direito de oposição: é o direito do usuário de, a qualquer momento, se opor por motivos relacionados com a sua situação particular, ao tratamento dos dados pessoais que lhe digam respeito, podendo se opor ainda ao uso de seus dados pessoais para definição de perfil de marketing (profiling);</p>
                <p className="recuoEsquerdo">- Direito de portabilidade dos dados: é o direito do usuário de receber os dados pessoais que lhe digam respeito e que tenha fornecido ao aplicativo, num formato estruturado, de uso corrente e de leitura automática, e o direito de transmitir esses dados a outro aplicativo;</p>
                <p className="recuoEsquerdo">- Direito de não ser submetido a decisões automatizadas: é o direito do usuário de não ficar sujeito a nenhuma decisão tomada exclusivamente com base no tratamento automatizado, incluindo a definição de perfis (profiling), que produza efeitos na sua esfera jurídica ou que o afete significativamente de forma similar.</p>
                <p>O usuário poderá exercer os seus direitos por meio de comunicação escrita enviada ao aplicativo com o assunto "RGDP", especificando:</p>
                <p className="recuoEsquerdo">- Nome completo ou razão social, número do CPF (Cadastro de Pessoas Físicas, da Receita Federal do Brasil) ou CNPJ (Cadastro Nacional de Pessoa Jurídica, da Receita Federal do Brasil) e endereço de e-mail do usuário e, se for o caso, do seu representante;</p>
                <p className="recuoEsquerdo">- Direito que deseja exercer junto ao aplicativo;</p>
                <p className="recuoEsquerdo">- Data do pedido e assinatura do usuário;</p>
                <p className="recuoEsquerdo">- Todo documento que possa demonstrar ou justificar o exercício de seu direito.</p>
                <p>O pedido deverá ser enviado ao e-mail: <a href="mailto: eduardo.gabriel@c2bsoftware.com.br">eduardo.gabriel@c2bsoftware.com.br</a>, ou por correio, ao seguinte endereço:</p>
                <p className="recuoEsquerdo subTitulo">C2B SOFTWARE DO BRASIL LTDA</p>
                <p className="recuoEsquerdo">Rua Yeda, n° 595, Tijuca, Teresópolis/RJ,</p>
                <p>O usuário será informado em caso de retificação ou eliminação dos seus dados.</p>
                <br></br>
                {/* Dever de não fornecer dados de terceiros */}
                <p className="subTitulo">3. Dever de não fornecer dados de terceiros</p>
                <p>Durante a utilização do aplicativo, a fim de resguardar e de proteger os direitos de terceiros, o usuário do aplicativo deverá fornecer somente seus dados pessoais, e não os de terceiros.</p>
                <br></br>
                {/* Informações coletadas */}
                <p className="subTitulo">4. Informações coletadas</p>
                <p>A coleta de dados dos usuários se dará em conformidade com o disposto nesta Política de Privacidade e dependerá do consentimento do usuário, sendo este dispensável somente nas hipóteses previstas no art. 11, inciso II, da Lei de Proteção de Dados Pessoais.</p>
                <p className="subTitulo">4.1. Tipos de dados coletados</p>
                <p className="subTitulo">4.1.1. Dados de identificação do usuário para realização de cadastro</p>
                <p>A utilização, pelo usuário, de determinadas funcionalidades do aplicativo dependerá de cadastro, sendo que, nestes casos, os seguintes dados do usuário poderão ser coletados e armazenados:</p>
                <p className="recuoEsquerdo">- nome</p>
                <p className="recuoEsquerdo">- data de nascimento (opcional)</p>
                <p className="recuoEsquerdo">- endereço de e-mail (opcional, porém necessário para usar o aplicativo)</p>
                <p className="recuoEsquerdo">- número de telefone (opcional)</p>
                <p className="recuoEsquerdo">- número de CPF</p>
                <p className="recuoEsquerdo">- endereço (opcional)</p>
                <p className="recuoEsquerdo">- bairro (opcional)</p>
                <p className="recuoEsquerdo">- cidade (opcional)</p>
                <p className="recuoEsquerdo">- uf (opcional)</p>
                <p className="recuoEsquerdo">- foto de usuário (opcional)</p>
                <p className="recuoEsquerdo"></p>
                <p className="subTitulo">4.1.2. Dados informados no formulário de contato</p>
                <p>Os dados eventualmente informados pelo usuário que utilizar o formulário de contato disponibilizado no aplicativo, incluindo o teor da mensagem enviada, serão coletados e armazenados.</p>
                <p className="subTitulo">4.1.3. Registros de acesso</p>
                <p>Em atendimento às disposições do art. 15, caput e parágrafos, da Lei Federal n. 12.965/2014 (Marco Civil da Internet), os registros de acesso do usuário serão coletados e armazenados por, pelo menos, seis meses.</p>
                <p className="subTitulo">4.1.4. Newsletter</p>
                <p>O endereço de e-mail cadastrado pelo usuário que optar por se inscrever em nossa Newsletter será coletado e armazenado até que o usuário solicite seu descadastro.</p>
                <p className="subTitulo">4.1.5. Dados sensíveis</p>
                <p><strong>Não</strong> serão coletados dados sensíveis dos usuários, assim entendidos aqueles definidos nos arts. 9º e 10 do RGPD e nos arts. 11 e seguintes da Lei de Proteção de Dados Pessoais. Assim, dentre outros, não haverá coleta dos seguintes dados:</p>
                <p className="recuoEsquerdo">- dados genéticos;</p>
                <p className="recuoEsquerdo">- dados biométricos para identificar uma pessoa de forma inequívoca;</p>
                <p className="recuoEsquerdo">- dados relativos à saúde do usuário;</p>
                <p className="recuoEsquerdo">- dados relativos à vida sexual ou à orientação sexual do usuário;</p>
                <p className="recuoEsquerdo">- dados relacionados a condenações penais ou a infrações ou com medidas de segurança conexas.</p>
                <p className="subTitulo">4.1.6. Coleta de dados não previstos expressamente</p>
                <p>Eventualmente, outros tipos de dados não previstos expressamente nesta Política de Privacidade poderão ser coletados, desde que sejam fornecidos com o consentimento do usuário, ou, ainda, que a coleta seja permitida ou imposta por lei.</p>
                <p className="subTitulo">4.2. Fundamento jurídico para o tratamento dos dados pessoais</p>
                <p>Ao utilizar os serviços do aplicativo, o usuário está consentindo com a presente Política de Privacidade.</p>
                <p>O usuário tem o direito de retirar seu consentimento a qualquer momento, não comprometendo a licitude do tratamento de seus dados pessoais antes da retirada. A retirada do consentimento poderá ser feita pelo e-mail: <a href="mailto: eduardo.gabriel@c2bsoftware.com.br">eduardo.gabriel@c2bsoftware.com.br</a>, ou por correio enviado ao seguinte endereço:</p>
                <p className="recuoEsquerdo">Rua Yeda, n° 595, Tijuca, Teresópolis/RJ</p>
                <p>O consentimento dos relativamente ou absolutamente incapazes, especialmente de crianças menores de 16 (dezesseis) anos, apenas poderá ser feito, respectivamente, se devidamente assistidos ou representados.</p>
                <p>Poderão ainda ser coletados dados pessoais necessários para a execução e cumprimento dos serviços contratados pelo usuário no aplicativo.</p>
                <p>O tratamento de dados pessoais sem o consentimento do usuário apenas será realizado em razão de interesse legítimo ou para as hipóteses previstas em lei, ou seja, dentre outras, as seguintes:</p>
                <p className="recuoEsquerdo">- para o cumprimento de obrigação legal ou regulatória pelo controlador;</p>
                <p className="recuoEsquerdo">- para a realização de estudos por órgão de pesquisa, garantida, sempre que possível, a anonimização dos dados pessoais;</p>
                <p className="recuoEsquerdo">- quando necessário para a execução de contrato ou de procedimentos preliminares relacionados a contrato do qual seja parte o usuário, a pedido do titular dos dados;</p>
                <p className="recuoEsquerdo">- para o exercício regular de direitos em processo judicial, administrativo ou arbitral, esse último nos termos da Lei nº 9.307, de 23 de setembro de 1996 (Lei de Arbitragem);</p>
                <p className="recuoEsquerdo">- para a proteção da vida ou da incolumidade física do titular dos dados ou de terceiro;</p>
                <p className="recuoEsquerdo">- para a tutela da saúde, em procedimento realizado por profissionais da área da saúde ou por entidades sanitárias;</p>
                <p className="recuoEsquerdo">- quando necessário para atender aos interesses legítimos do controlador ou de terceiro, exceto no caso de prevalecerem direitos e liberdades fundamentais do titular dos dados que exijam a proteção dos dados pessoais;</p>
                <p className="recuoEsquerdo">- para a proteção do crédito, inclusive quanto ao disposto na legislação pertinente.</p>
                <p className="subTitulo">4.3. Finalidades do tratamento dos dados pessoais</p>
                <p>Os dados pessoais do usuário coletados pelo aplicativo têm por finalidade facilitar, agilizar e cumprir os compromissos estabelecidos com o usuário e a fazer cumprir as solicitações realizadas por meio do preenchimento de formulários.</p>
                <p>Os dados pessoais poderão ser utilizados também com uma finalidade comercial, para personalizar o conteúdo oferecido ao usuário, bem como para dar subsídio ao aplicativo para a melhora da qualidade e funcionamento de seus serviços</p>
                <p>O aplicativo recolhe os dados do usuário para que seja realizada definição de perfis (profiling), ou seja, tratamento automatizado de dados pessoais que consista em utilizar estes dados para avaliar certos aspectos pessoais do usuário, principalmente para analisar ou prever características relacionadas ao seu desempenho profissional, a sua situação econômica, saúde, preferências pessoais, interesses, fiabilidade, comportamento, localização ou deslocamento.</p>
                <p>Os dados de cadastro serão utilizados para permitir o acesso do usuário a determinados conteúdos do aplicativo, exclusivos para usuários cadastrados.</p>
                <p>A coleta de dados relacionados ou necessários à execução de um contrato de compra e venda ou de prestação de serviços eventualmente firmado com o usuário terá a finalidade de conferir às partes segurança jurídica, além de facilitar e viabilizar a conclusão do negócio.</p>
                <p>O tratamento de dados pessoais para finalidades não previstas nesta Política de Privacidade somente ocorrerá mediante comunicação prévia ao usuário, sendo que, em qualquer caso, os direitos e obrigações aqui previstos permanecerão aplicáveis.</p>
                <p className="subTitulo">4.4. Prazo de conservação dos dados pessoais</p>
                <p>O período de conservação dos dados são definidos de acordo com os seguintes critérios:</p>
                <p className="recuoEsquerdo">Os dados serão armazenados pelo tempo necessário para a prestação dos serviços fornecidos pelo aplicativo de acordo com o contrato estabelecido entre a C2B SOFTWARE DO BRASIL LTDA e as empresas contratantes que fornecerão os devidos logins para os seus usuários.</p>
                <p>Os dados pessoais dos usuários apenas poderão ser conservados após o término de seu tratamento nas seguintes hipóteses:</p>
                <p className="recuoEsquerdo">- para o cumprimento de obrigação legal ou regulatória pelo controlador;</p>
                <p className="recuoEsquerdo">- para estudo por órgão de pesquisa, garantida, sempre que possível, a anonimização dos dados pessoais;</p>
                <p className="recuoEsquerdo">- para a transferência a terceiro, desde que respeitados os requisitos de tratamento de dados dispostos na legislação;</p>
                <p className="recuoEsquerdo">- para uso exclusivo do controlador, vedado seu acesso por terceiro, e desde que anonimizados os dados.</p>
                <p className="subTitulo">4.5. Destinatários e transferência dos dados pessoais</p>
                <p>Os dados pessoais do usuário não serão compartilhadas com terceiros. Serão, portanto, tratados apenas por este aplicativo.</p>
                <br></br>
                <p className="subTitulo">5. Do tratamento dos dados pessoais</p>
                <p className="subTitulo">5.1. Do responsável pelo tratamento dos dados (data controller)</p>
                <p>O controlador, responsável pelo tratamento dos dados pessoais do usuário, é a pessoa física ou jurídica, a autoridade pública, a agência ou outro organismo que, individualmente ou em conjunto com outras, determina as finalidades e os meios de tratamento de dados pessoais.</p>
                <p>Neste aplicativo, o responsável pelo tratamento dos dados pessoais coletados é C2B SOFTWARE DO BRASIL LTDA - CNPJ 33.103.137/0001-20, representada por Eduardo Gabriel Camelo Chiletto, que poderá ser contactado pelo e-mail: <a href="mailto: eduardo.gabriel@c2bsoftware.com.br">eduardo.gabriel@c2bsoftware.com.br</a> ou no endereço:</p>
                <p className="recuoEsquerdo">Rua Yeda, n° 595, Tijuca, Teresópolis, RJ</p>
                <p>O responsável pelo tratamento dos dados se encarregará diretamente do tratamento dos dados pessoais do usuário.</p>
                <p className="subTitulo">5.2. Do encarregado de proteção de dados (data protection officer)</p>
                <p>O encarregado de proteção de dados (data protection officer) é o profissional encarregado de informar, aconselhar e controlar o responsável pelo tratamento dos dados, bem como os trabalhadores que tratem os dados, a respeito das obrigações do aplicativo nos termos do RGDP, da Lei de Proteção de Dados Pessoais e de outras disposições de proteção de dados presentes na legislação nacional e internacional, em cooperação com a autoridade de controle competente.</p>
                <p>Neste aplicativo o encarregado de proteção de dados (data protection officer) é Eduardo Gabriel Camelo Chiletto, que poderá ser contactado pelo e-mail: <a href="mailto: eduardo.gabriel@c2bsoftware.com.br">eduardo.gabriel@c2bsoftware.com.br</a>.</p>
                <br></br>
                <p className="subTitulo">6. Segurança no tratamento dos dados pessoais do usuário</p>
                <p>O aplicativo se compromete a aplicar as medidas técnicas e organizativas aptas a proteger os dados pessoais de acessos não autorizados e de situações de destruição, perda, alteração, comunicação ou difusão de tais dados.</p>
                <p>Para a garantia da segurança, serão adotadas soluções que levem em consideração: as técnicas adequadas; os custos de aplicação; a natureza, o âmbito, o contexto e as finalidades do tratamento; e os riscos para os direitos e liberdades do usuário.</p>
                <p>O aplicativo utiliza certificado SSL (Secure Socket Layer) que garante que os dados pessoais se transmitam de forma segura e confidencial, de maneira que a transmissão dos dados entre o servidor e o usuário, e em retroalimentação, ocorra de maneira totalmente cifrada ou encriptada.</p>
                <p>No entanto, o aplicativo se exime de responsabilidade por culpa exclusiva de terceiro, como em caso de ataque de hackers ou crackers, ou culpa exclusiva do usuário, como no caso em que ele mesmo transfere seus dados a terceiro. O aplicativo se compromete, ainda, a comunicar o usuário em prazo adequado caso ocorra algum tipo de violação da segurança de seus dados pessoais que possa lhe causar um alto risco para seus direitos e liberdades pessoais.</p>
                <p>A violação de dados pessoais é uma violação de segurança que provoque, de modo acidental ou ilícito, a destruição, a perda, a alteração, a divulgação ou o acesso não autorizados a dados pessoais transmitidos, conservados ou sujeitos a qualquer outro tipo de tratamento.</p>
                <p>Por fim, o aplicativo se compromete a tratar os dados pessoais do usuário com confidencialidade, dentro dos limites legais.</p>
                <br></br>
                <p className="subTitulo">7. Dados de navegação (cookies)</p>
                <p>Cookies são pequenos arquivos de texto enviados pelo aplicativo ao computador do usuário e que nele ficam armazenados, com informações relacionadas à navegação do aplicativo.</p>
                <p>Por meio dos cookies, pequenas quantidades de informação são armazenadas pelo navegador do usuário para que nosso servidor possa lê-las posteriormente. Podem ser armazenados, por exemplo, dados sobre o dispositivo utilizado pelo usuário, bem como seu local e horário de acesso ao aplicativo.</p>
                <p>Os cookies não permitem que qualquer arquivo ou informação sejam extraídos do disco rígido do usuário, não sendo possível, ainda, que, por meio deles, se tenha acesso a informações pessoais que não tenham partido do usuário ou da forma como utiliza os recursos do aplicativo.</p>
                <p>Os cookies não permitem que qualquer arquivo ou informação sejam extraídos do disco rígido do usuário, não sendo possível, ainda, que, por meio deles, se tenha acesso a informações pessoais que não tenham partido do usuário ou da forma como utiliza os recursos do aplicativo.</p>
                <p>As informações eventualmente armazenadas em cookies que permitam identificar um usuário são consideradas dados pessoais. Dessa forma, todas as regras previstas nesta Política de Privacidade também lhes são aplicáveis.</p>
                <p className="subTitulo">7.1. Cookies do aplicativo</p>
                <p>Os cookies do aplicativo são aqueles enviados ao computador ou dispositivo do usuário e administrador exclusivamente pelo aplicativo.</p>
                <p>As informações coletadas por meio destes cookies são utilizadas para melhorar e personalizar a experiência do usuário, sendo que alguns cookies podem, por exemplo, ser utilizados para lembrar as preferências e escolhas do usuário, bem como para o oferecimento de conteúdo personalizado.</p>
                <p>Estes dados de navegação poderão, ainda, ser compartilhados com eventuais parceiros do aplicativo, buscando o aprimoramento dos produtos e serviços ofertados ao usuário.</p>
                <p className="subTitulo">7.2. Gestão dos cookies e configurações do navegador</p>
                <p>O usuário poderá se opor ao registro de cookies pelo aplicativo, bastando que desative esta opção no seu próprio navegador ou aparelho.</p>
                <p>A desativação dos cookies, no entanto, pode afetar a disponibilidade de algumas ferramentas e funcionalidades do aplicativo, comprometendo seu correto e esperado funcionamento. Outra consequência possível é remoção das preferências do usuário que eventualmente tiverem sido salvas, prejudicando sua experiência.</p>
                <p>A seguir, são disponibilizados alguns links para as páginas de ajuda e suporte dos navegadores mais utilizados, que poderão ser acessadas pelo usuário interessado em obter mais informações sobre a gestão de cookies em seu navegador:</p>
                <p className="subTitulo">
                    <a
                        href="https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-delete-manage-cookies"
                        target="_blank" rel="noopener noreferrer">Internet Explorer</a>
                </p>
                <p className="subTitulo">
                    <a
                        href="https://support.apple.com/pt-br/guide/safari/sfri11471/mac"
                        target="_blank" rel="noopener noreferrer">Safari</a>
                </p>
                <p className="subTitulo">
                    <a
                        href="https://support.google.com/chrome/answer/95647?hl=pt-BR&hlrm=pt"
                        target="_blank" rel="noopener noreferrer">Google Chrome</a>
                </p>
                <p className="subTitulo">
                    <a
                        href="https://support.mozilla.org/pt-BR/kb/ative-e-desative-os-cookies-que-os-aplicativos-usam"
                        target="_blank" rel="noopener noreferrer">Mozila Firefox</a>
                </p>

                <p className="subTitulo">
                    <a
                        href="https://www.opera.com/help/tutorials/security/privacy/"
                        target="_blank" rel="noopener noreferrer">Opera</a>
                </p>
                <br></br>
                <p className="subTitulo">8. Reclamação a uma autoridade de controle</p>
                <p>Sem prejuízo de qualquer outra via de recurso administrativo ou judicial, todos os titulares de dados têm direito a apresentar reclamação a uma autoridade de controle. A reclamação poderá ser feita à autoridade da sede do aplicativo, do país de residência habitual do usuário, do seu local de trabalho ou do local onde foi alegadamente praticada a infração.</p>
                <br></br>
                <p className="subTitulo">9. Das alterações</p>
                <p>A presente versão desta Política de Privacidade foi atualizada pela última vez em: 01/06/2020.</p>
                <p>O editor se reserva o direito de modificar, a qualquer momento e sem qualquer aviso prévio, o aplicativo as presentes normas, especialmente para adaptá-las às evoluções do aplicativo, seja pela disponibilização de novas funcionalidades, seja pela supressão ou modificação daquelas já existentes.</p>
                <p>Dessa forma, convida-se o usuário a consultar periodicamente esta página para verificar as atualizações.</p>
                <p>Ao utilizar o serviço após eventuais modificações, o usuário demonstra sua concordância com as novas normas. Caso discorde de alguma das modificações, deverá pedir, imediatamente, o cancelamento de sua conta e apresentar a sua ressalva ao serviço de atendimento, se assim o desejar.</p>
                <p className="subTitulo">10. Do Direito aplicável e do foro</p>
                <p>Para a solução das controvérsias decorrentes do presente instrumento, será aplicado integralmente o Direito brasileiro.</p>
                <p>Os eventuais litígios deverão ser apresentados no foro da comarca em que se encontra a sede do editor do aplicativo.</p>
            </div>
        )
    }

}

export default withRouter(Privacidade);    