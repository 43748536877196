import convenioDB from '../../../dataManager/dtmConvenio';
import { getOwnerId } from '../../../components/auth/auth-provider';
import beneficiarioDB from '../../../dataManager/dtmBeneficiario';
import movimentoDB from '../../../dataManager/dtmMovimentos';

const INDETERMINADO = 'Indeterminado';

export function getNextColor(color) {
    if (color === "#fff") {
        color = "#eee";
    } else {
        color = "#fff";
    }
    return color;
}

export async function getConvenios() {
    const ownerId = await getOwnerId();
    const convenios = await convenioDB.getByOwnerIdCustom(ownerId);
    return convenios
}

export async function getData(conveniosIds, dataIni, dataFim) {

    const ownerId = await getOwnerId();
    const inicio = dataIni.startOf('day').toDate();
    const fim = dataFim.endOf('day').toDate();
    const movimentos = await movimentoDB.getByPeriodo(inicio, fim, ownerId, conveniosIds)

    let beneficiarios = [];

    for (let index = 0; index < movimentos.length; index++) {
        const movimento = movimentos[index];

        let beneficiario = beneficiarios.filter((item) => {
            return item.id === movimento.beneficiarioId
        })[0];

        if (!beneficiario) {
            beneficiario = {
                key: movimento.key,
                id: movimento.beneficiarioId,
                nome: movimento.beneficiario ? movimento.beneficiario.nome : INDETERMINADO,
                valorDebitadoSaldoPre: movimento.valorDebitadoSaldoPre ? movimento.valorDebitadoSaldoPre : 0,
            }
            beneficiarios.push(beneficiario);
        } else {
            beneficiario.valorDebitadoSaldoPre += movimento.valorDebitadoSaldoPre ? movimento.valorDebitadoSaldoPre : 0;
            const listaBnfs = beneficiarios.filter((item) => {
                return item.id !== movimento.beneficiarioId
            });

            listaBnfs.push(beneficiario);
            beneficiarios = listaBnfs;
        }
    }
    let valorTotal = 0;
    for (let index = 0; index < beneficiarios.length; index++) {
        const beneficiario = beneficiarios[index];
        if (beneficiario.nome === INDETERMINADO) {
            const bnf = await beneficiarioDB.getById(beneficiario.id);
            beneficiarios[index].nome = `${bnf.nome.trim()} ${bnf.sobrenome.replace(/^\s+/, "").trim()}`
        } else {
            beneficiarios[index].nome = `${beneficiarios[index].nome}`
        }
        valorTotal += beneficiario.valorDebitadoSaldoPre;
        beneficiario.valorDebitadoSaldoPre = beneficiarioDB.formatarMoeda(beneficiario.valorDebitadoSaldoPre / 100);
    }

    beneficiarios.sort((a, b) => (a.nome > b.nome ? 1 : -1));

    const registros = beneficiarios.length > 1 ? 'registros' : 'registro'

    const beneficiario = {
        key: 'valorDebitadoSaldoPreTotal',
        id: 'valorDebitadoSaldoPreTotal',
        nome: `Total: (${beneficiarios.length} ${registros}) Valor:`,
        valorDebitadoSaldoPre: beneficiarioDB.formatarMoeda(valorTotal / 100),
    }

    beneficiarios.push(beneficiario);
    return beneficiarios;
}